import { useState } from 'react'

import { Checkbox } from '@sherweb/core/components/Checkbox'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@sherweb/core/components/DropdownMenu'
import { Typography } from '@sherweb/core/components/Typography'
import { useQueryParams } from '@sherweb/core/hooks'
import { DataTestId } from '@sherweb/core/types/dataTestIdType'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'
import { convertToCamelCase } from '@sherweb/core/utils/string'

import { IFacetCount } from '@ssp/modules/shop/core/shop.model'

interface ShopFilterProps extends DataTestId {
  className?: string
  trigger: React.ReactNode
  queryParamType:
    | 'productsVendor'
    | 'productsBillingCycle'
    | 'productsCommitment'
    | 'productsCategories'
  options?: IFacetCount[]
}

export const ShopFilter = ({
  dataTestId,
  className,
  trigger,
  options,
  queryParamType,
}: ShopFilterProps) => {
  const { getParams, setArrayParams, removeArrayParam } = useQueryParams<
    'productsVendor' | 'productsBillingCycle' | 'productsCommitment' | 'productsCategories'
  >()

  const [dropdownOpen, setDropdownOpen] = useState(false)

  return (
    <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
      <DropdownMenuTrigger asChild data-testid={`dropdown${convertToCamelCase(dataTestId)}`}>
        {trigger}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="start"
        className={mergeClassName('min-w-[225px]', className)}
        onFocusOutside={() => {
          setDropdownOpen(false)
        }}
      >
        {options?.map(option => (
          <DropdownMenuItem
            key={option.value}
            data-testid={`dropdownItem${convertToCamelCase(dataTestId)}`}
            onSelect={event => {
              event.preventDefault()
              if (option.value) {
                getParams(queryParamType).includes(option.value)
                  ? removeArrayParam(queryParamType, option.value)
                  : setArrayParams(queryParamType, option.value)
              }
            }}
          >
            <Checkbox
              data-testid={`checkbox${convertToCamelCase(dataTestId)}`}
              variant="secondary"
              checked={option?.value ? getParams(queryParamType).includes(option?.value) : false}
            />
            <Typography as="div" variant="body2" className="ml-2">
              {option.label}
            </Typography>
            <div className="absolute right-4">
              <Typography
                as="div"
                variant="label"
                className="rounded-lg bg-slate-200 px-1.5 py-0.5 dark:text-black"
              >
                {option.count}
              </Typography>
            </div>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
