import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { IntegrationType } from '@sherweb/core/openapi-generated/index.defs'

import { FormControl, FormField, FormItem } from '@sherweb/core/components/Form'
import { Separator } from '@sherweb/core/components/Separator'
import Switch from '@sherweb/core/components/Switch'
import { Typography } from '@sherweb/core/components/Typography'

import { useGetResellerIntegrationsQuery } from '@rsp/modules/integrations'

import { OrganizationDetailFormType } from './validationSchema/useOrganizationDetailSchema'

export const OrganizationDetailPortalFeatures = () => {
  const { t } = useTranslation()

  const form = useFormContext<OrganizationDetailFormType>()

  const integrationsQuery = useGetResellerIntegrationsQuery({
    staleTime: 0,
    refetchOnMount: true,
  })

  const hasConnectWiseIntegration =
    integrationsQuery.data?.helpDesk?.find(
      helpdeskIntegration => helpdeskIntegration.integrationType === IntegrationType.ConnectWise
    )?.configured ?? false

  return (
    <div className="mb-4 flex flex-col gap-y-2">
      <Typography variant="heading6" weight="bold">
        {t('rsp:pages.organizations.detail.portalFeatures.title')}
      </Typography>
      <Separator className="bg-slate-300 dark:bg-slate-700" />
      <div className="flex items-center justify-between">
        <Typography variant="body2" weight="default">
          {t('rsp:pages.organizations.detail.portalFeatures.toggles.userManagement')}
        </Typography>
        <FormField
          control={form.control}
          name="showUsers"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  data-testid="switchShowUsers"
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
      <div className="flex items-center justify-between">
        <Typography variant="body2" weight="default">
          {t('rsp:pages.organizations.detail.portalFeatures.toggles.subscriptionManagement')}
        </Typography>
        <FormField
          control={form.control}
          name="showSubscriptions"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  data-testid="switchShowSubscriptions"
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
      <div className="flex items-center justify-between">
        <Typography variant="body2" weight="default">
          {t('rsp:pages.organizations.detail.portalFeatures.toggles.licenseManagement')}
        </Typography>
        <FormField
          control={form.control}
          name="showLicenses"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  data-testid="switchShowLicenses"
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
      {hasConnectWiseIntegration ? (
        <div className="flex items-center justify-between">
          <Typography variant="body2" weight="default">
            {t('rsp:pages.organizations.detail.portalFeatures.toggles.helpDeskManagement')}
          </Typography>
          <FormField
            control={form.control}
            name="showHelpDesk"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Switch checked={field.value} onCheckedChange={field.onChange} />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
      ) : null}
    </div>
  )
}
