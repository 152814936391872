import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@sherweb/core/components/Button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@sherweb/core/components/DropdownMenu'
import { Permission } from '@sherweb/core/modules/authorization'
import { usePathGenerator } from '@sherweb/core/modules/browserRouter'
import { EMPTY_GUID } from '@sherweb/core/utils/const'

import Routes from '@rsp/app/Routes'
import { HasAccess } from '@rsp/modules/authorization'
import { useNavigate } from '@rsp/modules/navigation'
import {
  Order,
  OrderDecision,
  OrderStatus,
  OrderType,
  useProcessOrderMutation,
} from '@rsp/modules/orders'

import { SubscriptionStatus } from '@ssp/modules/subscription'

import { OrderAprovalConfirmationDialog } from '../../components/OrderAprovalConfirmationDialog'

export type OrdersTableRowActionsProps = {
  order: Order
}

export const OrdersTableRowActions = ({ order }: OrdersTableRowActionsProps) => {
  const { t } = useTranslation()

  const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(false)

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const [decision, setDecision] = useState<OrderDecision | null>(null)

  const { navigate } = useNavigate()

  const decisionFlag = order?.orderStatus === OrderStatus.WaitingForApproval

  const viewSubscriptionFlag =
    order?.firstSubscriptionId !== EMPTY_GUID &&
    order?.firstSubscriptionStatus !== SubscriptionStatus.Inactive &&
    order?.organizationUniqueName

  const viewSubscriptionOptionFlag =
    order.orderType === OrderType.Amendment &&
    order.firstSubscriptionStatus !== SubscriptionStatus.Inactive

  const pathGenerator = usePathGenerator()

  const processOrderMutation = useProcessOrderMutation()

  const navigateToSubscriptionView = () => {
    if (viewSubscriptionFlag) {
      const subscriptionPath = pathGenerator(Routes.Subscription, {
        subscriptionId: order?.firstSubscriptionId ?? '',
        SubscriptionStatus: order?.firstSubscriptionStatus ?? '',
        organizationUniqueName: order?.organizationUniqueName ?? '',
      })
      window.open(subscriptionPath, '_blank')
    }
  }

  const handleShowDetails = () => {
    navigate(Routes.Order, { orderId: order.id })
  }

  const setDecisionAndOpenDialog = (decision: OrderDecision) => {
    setDecision(decision)
    setConfirmationDialogOpened(true)
    setDropdownOpen(false)
  }

  const cancelConfirmation = () => {
    setDecision(null)
    setConfirmationDialogOpened(false)
    setDropdownOpen(false)
  }

  const onSubmit = (decision: OrderDecision, decisionNote?: string) => {
    processOrderMutation.mutate({
      orderId: order.id,
      organizationId: order.organizationId,
      decision,
      decisionNote,
    })
  }

  return (
    <>
      <div className="flex gap-x-4">
        <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
          <DropdownMenuTrigger asChild data-testid="dropdownViewOrderOptions">
            <Button variant="ghost" className="h-8 w-8 p-0">
              <EllipsisVerticalIcon className="mr-0.5 w-6 text-gray-500 hover:bg-slate-50" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem data-testid="dropdownItemViewDetail" onClick={handleShowDetails}>
              {t('rsp:pages.orders.list.actions.viewDetails')}
            </DropdownMenuItem>
            {viewSubscriptionOptionFlag && (
              <DropdownMenuItem
                onClick={navigateToSubscriptionView}
                data-testid="dropdownItemViewSubscription"
              >
                {t('rsp:pages.orders.list.actions.viewSubscription')}
              </DropdownMenuItem>
            )}
            {decisionFlag && (
              <HasAccess permission={Permission.OrderApproval}>
                <DropdownMenuItem
                  data-testid="dropdownItemApprove"
                  onClick={() => setDecisionAndOpenDialog(OrderDecision.Approve)}
                >
                  {t('rsp:pages.orders.list.actions.approve')}
                </DropdownMenuItem>
                <DropdownMenuItem
                  data-testid="dropdownItemReject"
                  onClick={() => setDecisionAndOpenDialog(OrderDecision.Reject)}
                >
                  {t('rsp:pages.orders.list.actions.reject')}
                </DropdownMenuItem>
              </HasAccess>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      {decisionFlag && decision && (
        <OrderAprovalConfirmationDialog
          decision={decision}
          order={order}
          cancelConfirmation={cancelConfirmation}
          onSubmit={onSubmit}
          confirmationDialogOpened={confirmationDialogOpened}
        />
      )}
    </>
  )
}
