// based on: https://ui.shadcn.com/docs/components/alert#installation

import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { cva, type VariantProps } from 'class-variance-authority'
import { forwardRef } from 'react'

import { mergeClassName } from '../../utils/mergeClassName'
import Button from '../Button/Button'

export enum Variant {
  Default = 'default',
  Info = 'info',
  Warning = 'warning',
  Danger = 'danger',
  Success = 'success',
}

const alertVariants = cva(
  'relative w-full rounded-lg px-4 py-3 pl-10 text-sm [&>svg]:absolute [&>svg]:left-4',
  {
    variants: {
      variant: {
        [Variant.Default as string]:
          'bg-gray-100  text-gray-600 dark:bg-gray-500 dark:text-gray-100',
        [Variant.Info as string]:
          'bg-indigo-100 text-indigo-600 dark:bg-indigo-500 dark:text-indigo-100',
        [Variant.Warning as string]:
          'bg-yellow-100 text-orange-600 dark:bg-yellow-500 dark:text-orange-100',
        [Variant.Danger as string]: 'bg-red-100 text-red-600 dark:bg-red-500 dark:text-red-100',
        [Variant.Success as string]:
          'bg-green-100 text-green-600 dark:bg-green-500 dark:text-green-100',
      },
    },
    defaultVariants: {
      variant: Variant.Default,
    },
  }
)

const AlertContainer = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={mergeClassName(alertVariants({ variant }), className)}
    {...props}
  />
))
AlertContainer.displayName = 'Alert'

const AlertTitle = forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ children, className, ...props }, ref) => (
    <h5
      ref={ref}
      className={mergeClassName('mb-1 font-medium leading-none tracking-tight', className)}
      {...props}
    >
      {children}
    </h5>
  )
)
AlertTitle.displayName = 'AlertTitle'

const AlertDescription = forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={mergeClassName('text-sm [&_p]:leading-relaxed', className)}
    {...props}
  />
))
AlertDescription.displayName = 'AlertDescription'

const getIcon = (variant?: Variant) => {
  switch (variant) {
    case Variant.Info:
      return InformationCircleIcon
    case Variant.Warning:
    case Variant.Danger:
      return ExclamationTriangleIcon
    case Variant.Success:
      return CheckCircleIcon
    default:
      return null
  }
}

type AlertProps = {
  title?: React.ReactNode
  children?: React.ReactNode
  onClose?: () => void
} & React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof alertVariants>

const Alert: React.FC<AlertProps> = ({
  title,
  children,
  variant,
  className,
  onClose,
  ...props
}) => {
  const Icon = getIcon(variant as Variant)
  return (
    <AlertContainer
      {...props}
      variant={variant}
      className={mergeClassName('flex items-center', className)}
    >
      {Icon && <Icon className="h-4 w-4" />}
      {title && <AlertTitle>{title}</AlertTitle>}
      <AlertDescription className="grow">{children}</AlertDescription>
      {onClose && (
        <Button
          variant="none"
          data-testid="alert-close-button"
          size="small"
          className="h-5 w-4 items-center text-inherit"
          onClick={() => onClose()}
        >
          <XMarkIcon className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </Button>
      )}
    </AlertContainer>
  )
}

export default Alert
