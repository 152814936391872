import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Accordion,
  AccordionContent,
  AccordionContentList,
  AccordionContentListItem,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerSubContent,
} from '@sherweb/core/components/Accordion'
import Button from '@sherweb/core/components/Button'
import { DataTableMobileHeader } from '@sherweb/core/components/DataTable/DataTableInputMobile'
import { useSetTableSearchParams } from '@sherweb/core/components/DataTable/hooks/useSetTableSearchParam'
import { Permission } from '@sherweb/core/modules/authorization'

import { HasAccess } from '@ssp/modules/authorization'
import { License, useLicenses, useRefreshData } from '@ssp/modules/license'

import SubscribersDialog from '../SubscribersDialog'

const LicenseTableMobile = () => {
  const { t } = useTranslation()

  const { isLoading, error, data: licenses = [] } = useLicenses()

  const { refreshData } = useRefreshData()

  const [openDialog, setOpenDialog] = useState(false)

  const [selectedLicense, setSelectedLicense] = useState<License>()

  const { search: searchText, onSetSearchParams } = useSetTableSearchParams()

  const handleRefreshLicenses = async () => {
    await refreshData()
  }

  const getUsage = (available: number, used: number) => {
    if (available <= 0) {
      throw new Error('error')
    }

    const availableLicensesQty = available - used

    return (
      <div>
        <span className="font-base">{t('ssp:pages.licenses.list.availableLicenses')}</span>{' '}
        {availableLicensesQty}
      </div>
    )
  }

  const filteredData = licenses.filter(license => {
    return (license?.productName ?? '').toLowerCase().includes(searchText.toLowerCase())
  })

  if (isLoading || error) {
    return null
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="mb-4 flex justify-between gap-4">
          <DataTableMobileHeader>
            <DataTableMobileHeader.Input
              placeholder={t('ssp:pages.licenses.list.filterPlaceholder')}
              filteredText={searchText}
              setFilteredText={onSetSearchParams}
            />
            <Button
              variant="primary"
              size="sm"
              className="h-full"
              data-testid="btnRefreshLicensesMobile"
              onClick={handleRefreshLicenses}
            >
              <ArrowPathIcon className="h-4 w-4" />
            </Button>
          </DataTableMobileHeader>
        </div>
        {filteredData.map((license: License) => {
          return (
            <Accordion key={license.id} type="single" collapsible data-testid="licenseAccordion">
              <AccordionItem value="item-1">
                <AccordionTrigger className="relative justify-between rounded-xl bg-white p-4 [&[data-state=open]>div>div]:hidden [&[data-state=open]]:rounded-b-none">
                  {license.productName}
                  <AccordionTriggerSubContent>
                    {getUsage(license.availableQuantity, license.numberUsers)}
                  </AccordionTriggerSubContent>
                </AccordionTrigger>
                <AccordionContent>
                  <AccordionContentList>
                    <AccordionContentListItem
                      label={t('ssp:pages.licenses.list.columns.vendorName')}
                      value={license.vendorName}
                      dataTestId="accVendorNameLicenses"
                    />
                    <AccordionContentListItem
                      label={t('ssp:pages.licenses.list.columns.licenses')}
                      value={license.availableQuantity}
                      dataTestId="accAvailibilityLicenses"
                    />
                    <AccordionContentListItem
                      label={t('ssp:pages.licenses.list.columns.users')}
                      value={license.numberUsers}
                      dataTestId="accNumberUserLicenses"
                    />
                    <AccordionContentListItem
                      label=""
                      value={
                        <HasAccess permission={Permission.LicenseDetails}>
                          <Button
                            variant="outline"
                            size="sm"
                            type="button"
                            data-testid="btnManageSubscribersMobile"
                            onClick={() => {
                              setSelectedLicense(license)
                              setOpenDialog(true)
                            }}
                          >
                            {t('ssp:pages.licenses.manage')}
                          </Button>
                        </HasAccess>
                      }
                    />
                  </AccordionContentList>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          )
        })}
      </div>
      {selectedLicense && (
        <SubscribersDialog
          open={openDialog}
          onOpenChange={setOpenDialog}
          license={selectedLicense}
        />
      )}
    </>
  )
}

export default LicenseTableMobile
