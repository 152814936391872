import { useTranslation } from 'react-i18next'

import { OrderStatus, OrderType } from '@sherweb/core/openapi-generated/index.defs'

import Badge, { Variant } from '@sherweb/core/components/Badge'

export const useRenderStatusBadge = () => {
  const { t } = useTranslation()

  return (status: OrderStatus) => {
    switch (status) {
      case OrderStatus.Completed:
        return (
          <Badge variant={Variant.Success} className="ml-4">
            {t('ssp:pages.orders.list.statuses.complete')}
          </Badge>
        )
      case OrderStatus.Failed:
        return (
          <Badge variant={Variant.Danger} className="ml-4">
            {t('ssp:pages.orders.list.statuses.failed')}
          </Badge>
        )
      case OrderStatus.Pending:
        return (
          <Badge variant={Variant.Warning} className="ml-4">
            {t('ssp:pages.orders.list.statuses.pending')}
          </Badge>
        )
      case OrderStatus.Rejected:
        return (
          <Badge variant={Variant.Danger} className="ml-4">
            {t('ssp:pages.orders.list.statuses.rejected')}
          </Badge>
        )
      case OrderStatus.WaitingForApproval:
        return (
          <Badge variant={Variant.Warning} className="ml-4">
            {t('ssp:pages.orders.list.statuses.waitingForApproval')}
          </Badge>
        )
    }
  }
}

export const useRenderOrderType = () => {
  const { t } = useTranslation()

  return (type?: OrderType) => {
    if (type === OrderType.Amendment) {
      return <div>{t('ssp:pages.orders.list.types.update')}</div>
    }

    return <div>{t('ssp:pages.orders.list.types.purchase')}</div>
  }
}
