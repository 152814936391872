import * as z from 'zod'

const intergrationSettingsSchema = z.object({
  subresellerVisible: z.boolean(),
  priceVisible: z.boolean(),
  showSubscriptions: z.boolean(),
  showUsers: z.boolean(),
  showLicenses: z.boolean(),
  showBilling: z.boolean(),
  orgAdminCanCreateUsers: z.boolean(),
  allowSubscriptionCancellation: z.boolean(),
})

export default intergrationSettingsSchema
