import {
  ArrowUpTrayIcon,
  PencilSquareIcon,
  UserPlusIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  Accordion,
  AccordionContent,
  AccordionContentList,
  AccordionContentListItem,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerSubContent,
} from '@sherweb/core/components/Accordion'
import Button from '@sherweb/core/components/Button'
import Card from '@sherweb/core/components/Card'
import ConfirmationDialog from '@sherweb/core/components/ConfirmationDialog'
import { DataTableMobileHeader } from '@sherweb/core/components/DataTable/DataTableInputMobile'
import { useSetTableSearchParams } from '@sherweb/core/components/DataTable/hooks/useSetTableSearchParam'
import { Permission } from '@sherweb/core/modules/authorization'
import { useDateFormatter } from '@sherweb/core/utils/date'

import Routes from '@ssp/app/Routes'
import Link from '@ssp/components/Link'
import { HasAccess, usePermissionCheck } from '@ssp/modules/authorization'
import {
  useDeleteUser,
  User,
  useResendInvitationMutation,
  useUserRolesTranslation,
  useUsersByOrganization,
  useUserStatusBadge,
} from '@ssp/modules/user'

const UsersTableMobile: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { isFetching, error, data: users = [] } = useUsersByOrganization()

  const [deleting, setDeleting] = useState(false)

  const [selectedUser, setSelectedUser] = useState<User>()

  const { deleteUser } = useDeleteUser()

  const formatDate = useDateFormatter(language)

  const getUserRoles = useUserRolesTranslation()

  const userStatusBadge = useUserStatusBadge()

  const { search: searchText, onSetSearchParams } = useSetTableSearchParams()

  const { hasAccess } = usePermissionCheck()

  const isOptionsVisible =
    hasAccess(Permission.UsersDelete) ||
    hasAccess(Permission.UsersCreate) ||
    hasAccess(Permission.UsersEdit)

  const filteredData = users?.filter(user => {
    const fullName = `${user.firstName ?? ''} ${user.lastName ?? ''}`.toLowerCase()

    return (
      fullName?.includes(searchText.toLowerCase()) ||
      (user.email?.toLowerCase() ?? '').includes(searchText.toLowerCase()) ||
      user.jobTitle?.toLowerCase().includes(searchText.toLowerCase())
    )
  })

  const resendInvitationMutation = useResendInvitationMutation()

  const handleResendInvitation = (user: User) => {
    resendInvitationMutation.mutate(user)
  }

  if (isFetching || error) {
    return null
  }

  return (
    <>
      <Card padded>
        <div className="flex flex-col gap-4">
          <div className="mb-4 flex justify-between gap-4">
            <DataTableMobileHeader>
              <DataTableMobileHeader.Input
                placeholder={t('ssp:pages.users.list.filterPlaceholder')}
                filteredText={searchText}
                setFilteredText={onSetSearchParams}
              />
              <HasAccess permission={Permission.UsersCreate}>
                <Button
                  variant="primary"
                  size="sm"
                  className="h-full"
                  onClick={() => {}}
                  data-testid="btnAddUserMobile"
                  asChild
                >
                  <Link
                    to={Routes.UsersInvite}
                    className="rounded-lg focus-visible:outline-indigo-300"
                  >
                    <UserPlusIcon className="h-4 w-4" />
                  </Link>
                </Button>
              </HasAccess>
            </DataTableMobileHeader>
          </div>

          {filteredData.map((user: User) => {
            const fullName = `${user.firstName as string} ${user.lastName as string}`
            return (
              <Accordion key={user.id} type="single" collapsible>
                <AccordionItem value="item-1">
                  <AccordionTrigger className="relative justify-between rounded-xl bg-white p-4 [&[data-state=open]>div>div]:hidden [&[data-state=open]]:rounded-b-none">
                    <span>{fullName}</span>
                    <AccordionTriggerSubContent>{user.email}</AccordionTriggerSubContent>
                  </AccordionTrigger>
                  <AccordionContent>
                    <AccordionContentList>
                      <AccordionContentListItem
                        label={t('ssp:pages.users.list.columns.email')}
                        value={user.email}
                        dataTestId="accEmailUser"
                      />
                      <AccordionContentListItem
                        label={t('ssp:pages.users.list.columns.jobTitle')}
                        value={user.jobTitle}
                        dataTestId="accJobUser"
                      />
                      <AccordionContentListItem
                        label={t('ssp:pages.users.list.columns.roles')}
                        value={getUserRoles(user.roles)}
                        dataTestId="accRolesUser"
                      />
                      <AccordionContentListItem
                        label={t('ssp:pages.users.list.columns.createdOn')}
                        value={formatDate(user.createdAt)}
                        dataTestId="accCreationUser"
                      />
                      <AccordionContentListItem
                        label={t('ssp:pages.users.list.columns.status')}
                        value={userStatusBadge(user.status)}
                        dataTestId="accCreationUser"
                      />
                      {isOptionsVisible ? (
                        <AccordionContentListItem
                          label=" "
                          value={
                            <div className="flex items-center gap-x-2">
                              <HasAccess permission={Permission.UsersCreate}>
                                <Button
                                  className="gap-1 p-0"
                                  variant="none"
                                  disabled={resendInvitationMutation?.isLoading}
                                  data-testid="btnResendInvitationUserMobile"
                                  onClick={() => handleResendInvitation(user)}
                                >
                                  <>
                                    <ArrowUpTrayIcon className="h-5 w-5 stroke-1 text-indigo-500 dark:stroke-2" />
                                    <span className="text-indigo-500">
                                      {t('ssp:pages.users.list.resendInvitation')}
                                    </span>
                                  </>
                                </Button>
                              </HasAccess>
                              <HasAccess permission={Permission.UsersEdit}>
                                <Button
                                  asChild
                                  className="ml-2 p-0"
                                  variant="none"
                                  data-testid="btnEditUserMobile"
                                >
                                  <Link
                                    to={Routes.UserEdit}
                                    params={{ userId: user.id }}
                                    className="flex gap-1"
                                  >
                                    <PencilSquareIcon className="h-5 w-5 stroke-1 text-indigo-500 dark:stroke-2" />
                                    <span className="text-indigo-500">
                                      {t('ssp:pages.users.list.edit')}
                                    </span>
                                  </Link>
                                </Button>
                              </HasAccess>
                              <HasAccess permission={Permission.UsersDelete}>
                                <button
                                  className="ml-2 flex flex-row gap-1 text-indigo-500"
                                  data-testid="btnDeleteUserMobile"
                                  onClick={() => {
                                    setSelectedUser(user)
                                    setDeleting(true)
                                  }}
                                >
                                  <XCircleIcon className="inline h-5 w-5 stroke-1 text-indigo-500 dark:stroke-2" />
                                  <span>{t('ssp:pages.users.list.delete')}</span>
                                </button>
                              </HasAccess>
                            </div>
                          }
                        />
                      ) : null}
                    </AccordionContentList>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            )
          })}
        </div>
      </Card>
      {selectedUser && (
        <ConfirmationDialog
          open={deleting}
          title={t('ssp:pages.users.userDeletionDialog.title')}
          onClose={() => setDeleting(false)}
          onConfirm={() => {
            deleteUser(selectedUser.id)
          }}
          confirmButtonText={t('ssp:actions.delete')}
          cancelButtonText={t('ssp:actions.cancel')}
          destructive
        >
          <Trans
            i18nKey="ssp:pages.users.userDeletionDialog.description"
            values={{
              user:
                selectedUser.email ??
                `${selectedUser?.firstName ?? ''} ${selectedUser.lastName ?? ''}`,
            }}
          />
        </ConfirmationDialog>
      )}
    </>
  )
}

export default UsersTableMobile
