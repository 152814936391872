import { useTranslation } from 'react-i18next'

import Badge, { Variant } from '@sherweb/core/components/Badge'

import { OrderError, OrderStatus, OrderType } from './orders.model'

export const useRenderOrderType = () => {
  const { t } = useTranslation()

  return (type?: OrderType | null) => {
    if (type === OrderType.Amendment) {
      return <div>{t('rsp:pages.orders.list.types.update')}</div>
    }

    return <div>{t('rsp:pages.orders.list.types.purchase')}</div>
  }
}

export const useOrderStatusBadge = () => {
  const { t } = useTranslation()

  const statusBadgeMapper = {
    [OrderStatus.Failed]: (
      <Badge variant={Variant.Danger}>{t('rsp:pages.orders.status.failed')}</Badge>
    ),
    [OrderStatus.Pending]: (
      <Badge variant={Variant.Warning}>{t('rsp:pages.orders.status.pending')}</Badge>
    ),
    [OrderStatus.Completed]: (
      <Badge variant={Variant.Success}>{t('rsp:pages.orders.status.completed')}</Badge>
    ),
    [OrderStatus.WaitingForApproval]: (
      <Badge variant={Variant.Warning}>{t('rsp:pages.orders.status.waitingForApproval')}</Badge>
    ),
    [OrderStatus.Rejected]: (
      <Badge variant={Variant.Danger}>{t('rsp:pages.orders.status.rejected')}</Badge>
    ),
  }

  return (value?: OrderStatus | null) => (value ? statusBadgeMapper[value] : null)
}

export const decode = (val?: string | null) => {
  try {
    return decodeURIComponent(encodeURIComponent(val ?? ''))
  } catch (error) {
    console.error(error)
  }
}

export const getServerError = (error?: OrderError | null) => {
  return (
    <>
      {error?.title && !error?.detail?.includes(error.title) && <p>{decode(error.title)}</p>}
      <p>{decode(error?.detail)}</p>
    </>
  )
}
