import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query'

import { AmendmentOrdersService } from '@sherweb/core/openapi-generated/AmendmentOrdersService'
import { OrdersQueryResult, ProcessOrderDecision } from '@sherweb/core/openapi-generated/index.defs'

import { useSelectedResellerId } from '@rsp/modules/reseller'

import { buildOrders, mapUpdateOnDataOnProcessOrder } from './orders.builder'
import { Order, OrderDecision, ProcessOrder } from './orders.model'
import { ordersByResellerId } from './orders.queries'

export const useGetBaseOrdersQuery = <TResult = OrdersQueryResult[],>(
  options?: Omit<UseQueryOptions<OrdersQueryResult[], Error, TResult>, 'queryKey'>
) => {
  const resellerId = useSelectedResellerId()

  return useQuery<OrdersQueryResult[], Error, TResult>({
    queryKey: ordersByResellerId.queryKey(resellerId),
    queryFn: async () => await ordersByResellerId.queryFn(resellerId),
    enabled: !!resellerId,
    ...options,
  })
}

export const useGetOrdersQuery = () => {
  return useGetBaseOrdersQuery<Order[]>({
    select: data => buildOrders(data),
  })
}

export const useGetOrderQuery = (orderId?: string) => {
  const resellerId = useSelectedResellerId()

  return useGetBaseOrdersQuery<Order | undefined>({
    enabled: !!resellerId && !!orderId,
    select: data => buildOrders(data)?.find(s => s.id === orderId),
  })
}

export const useProcessOrderMutation = () => {
  const queryClient = useQueryClient()
  const selectedResellerId = useSelectedResellerId()
  return useMutation({
    // eslint-disable-next-line @typescript-eslint/return-await
    mutationFn: async (processOrder: ProcessOrder) => {
      if (!processOrder.decision) {
        throw new Error('Missing decision')
      }
      if (!processOrder.orderId) {
        throw new Error('Missing order id')
      }
      if (!processOrder.organizationId) {
        throw new Error('Missing organization id')
      }

      return await AmendmentOrdersService.amendmentOrdersProcessOrder({
        command: {
          decision:
            processOrder.decision === OrderDecision.Approve
              ? ProcessOrderDecision.Approve
              : ProcessOrderDecision.Reject,
          orderIds: [processOrder.orderId],
          organizationId: processOrder.organizationId,
          decisionNote: processOrder.decisionNote ?? undefined,
        },
      })
    },
    onSuccess: async (_, variables) => {
      queryClient.setQueryData<OrdersQueryResult[]>(
        ordersByResellerId.queryKey(selectedResellerId),
        oldData => mapUpdateOnDataOnProcessOrder(oldData, variables)
      )
    },
  })
}
