import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DetailedOrganizationListResult } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@sherweb/core/components/DropdownMenu'

import Routes from '@rsp/app/Routes'
import { useNavigate } from '@rsp/modules/navigation'
import { GoToOrganizationLink } from '@rsp/pages/organizations/components/GoToOrganizationLink'

export type OrganizationTableRowActionsProps = {
  organization: DetailedOrganizationListResult
}

export const OrganizationsTableRowActions = ({
  organization,
}: OrganizationTableRowActionsProps) => {
  const { t } = useTranslation()

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const { navigate } = useNavigate()

  const handleShowDetails = () => {
    navigate(Routes.Organization, { organizationId: organization.organizationId })
  }

  return (
    <div className="flex gap-x-4">
      <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <DropdownMenuTrigger asChild data-testid="dropdownViewOrganizationOptions">
          <Button variant="ghost" className="h-8 w-8 p-0">
            <EllipsisVerticalIcon className="mr-0.5 w-6 text-gray-500 hover:bg-slate-50" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem data-testid="dropdownItemViewSettings" onClick={handleShowDetails}>
            {t('rsp:pages.organizations.list.actions.viewSettings')}
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <GoToOrganizationLink
              className="hover:bg-accent hover:text-accent-foreground"
              dataTestId="dropdownItemViewOrganization"
              organizationUniqueName={organization?.uniqueName}
            >
              {t('rsp:pages.organizations.list.actions.openOrganization')}
            </GoToOrganizationLink>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
