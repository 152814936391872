import { rankings, rankItem } from '@tanstack/match-sorter-utils'
import {
  ColumnDef,
  FilterFn,
  getCoreRowModel,
  SortingState,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table'
import { useState } from 'react'
import isEqual from 'react-fast-compare'

import { usePrevious } from '@sherweb/core/hooks/usePrevious'
import { DEFAULT_TABLE_ROWS_PER_PAGE } from '@sherweb/core/utils/const'

import { PaginationSortParam } from '../types'
import { useSetSorting } from './useSetSorting'

type useDataTableProps<TColumns, TData> = {
  filterableFields?: string[]
  data?: TData[]
  rowsPerPage?: number
  columns: Array<ColumnDef<TData, TColumns>>
  onSortBy?: (params: PaginationSortParam) => void
} & Partial<Omit<TableOptions<TData>, 'columns'>>

export const useDataTable = <TColumns, TData>({
  data = [],
  columns,
  rowsPerPage = DEFAULT_TABLE_ROWS_PER_PAGE,
  filterableFields,
  onSortBy,
  ...options
}: useDataTableProps<TColumns, TData>) => {
  const [sorting, setSorting] = useState<SortingState>([])

  const previousSorting = usePrevious(sorting)

  const [globalFilter, setGlobalFilter] = useState('')

  const { onSortByServer } = useSetSorting({ sorting, onSortBy })

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    if (!filterableFields?.includes(columnId)) {
      return false
    }

    const itemRank = rankItem(row.getValue(columnId), value, { threshold: rankings.CONTAINS })

    addMeta({
      itemRank,
    })

    return itemRank.passed
  }

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    globalFilterFn: fuzzyFilter,
    state: {
      sorting,
      globalFilter,
    },
    initialState: {
      pagination: {
        pageSize: rowsPerPage,
      },
    },
    defaultColumn: {
      minSize: 64,
    },
    ...options,
  })

  return {
    table,
    setGlobalFilter,
    sorting,
    hasSortingChanged: !isEqual(previousSorting, sorting),
    onSortByServer,
  }
}
