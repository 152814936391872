import { CurrencyDollarIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { CommitmentTermType, Facet } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import { useQueryParams } from '@sherweb/core/hooks'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import { ShopFilter } from './ShopFilter'
import { ShopFilterTriggerContent } from './ShopFilterTriggerContent'

type ShopCommitmentFilterProps = {
  facets?: Facet[]
}

export const ShopCommitmentFilter = ({ facets }: ShopCommitmentFilterProps) => {
  const { t } = useTranslation()

  const commitmentTermOptions =
    facets
      ?.find(({ fieldName }) => fieldName === 'offers.commitment_term')
      ?.countsByValue?.map(option => ({
        ...option,
        label: t(`ssp:pages.shop.filters.commitmentValues.${option.value as CommitmentTermType}`),
      })) ?? []

  const { getArrayParam } = useQueryParams<'productsCommitment'>()

  const queryCommitmentTermSelectedValues = getArrayParam<CommitmentTermType>('productsCommitment')

  const queryCommitmentTermSelectedLabels = queryCommitmentTermSelectedValues?.map(value =>
    t(`ssp:pages.shop.filters.commitmentValues.${value}`)
  )

  return (
    <ShopFilter
      dataTestId="commitmentTerm"
      options={commitmentTermOptions}
      queryParamType="productsCommitment"
      trigger={
        <Button
          size="small"
          variant="None"
          className={mergeClassName(
            'flex items-center px-4 py-2 text-slate-900 hover:bg-white/50 dark:text-slate-100',
            {
              'bg-white/50': queryCommitmentTermSelectedValues.length !== 0,
            }
          )}
        >
          <ShopFilterTriggerContent
            text={t('ssp:pages.shop.filters.commitment')}
            icon={<CurrencyDollarIcon className="mr-1 h-4 w-4" />}
            value={queryCommitmentTermSelectedLabels}
          />
        </Button>
      }
    />
  )
}
