import { useCallback, useState } from 'react'

import { getDefaultParameters } from '../const'
import { Pagination, PaginationSort } from '../types'
import { useSetTableSearchParams } from './useSetTableSearchParam'

const getSortField = (sortField: string) => sortField.charAt(0).toUpperCase() + sortField.slice(1)

type UseServerPaginationProps<T> = {
  sort?: PaginationSort<T>
}

export const useServerPagination = <T>({ sort }: UseServerPaginationProps<T>) => {
  const { search: searchTerm } = useSetTableSearchParams()

  const [options, setOptions] = useState(() =>
    getDefaultParameters({
      sort,
      searchTerm,
    })
  )

  const onSortBy = useCallback(
    ({ sortField, sortAscending }: { sortField: string; sortAscending: boolean }) => {
      setOptions(option => ({
        ...option,
        sort: {
          field: getSortField(sortField) as T,
          ascending: sortAscending,
        },
      }))
    },
    []
  )

  const handleOptions = useCallback(({ pageSize, page, searchTerm }: Pagination) => {
    setOptions(option => ({
      ...option,
      pageSize,
      searchTerm,
      page,
    }))
  }, [])

  return { options, setOptions: handleOptions, onSortBy }
}
