import { useTranslation } from 'react-i18next'

import DataTable from '@sherweb/core/components/DataTable'
import { Columns } from '@sherweb/core/components/DataTable/types'
import { dateFormatTypes, useDateFormatter } from '@sherweb/core/utils/date'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import { Order, useGetOrdersQuery } from '@rsp/modules/orders'
import { useOrderStatusBadge } from '@rsp/modules/orders/core/orders.helpers'

import { OrdersTableRowActions } from './components/OrdersTableRowActions'
import {
  OrdersTableCreatedByNameRow,
  OrdersTableCreatedByNameRowProps,
} from './OrdersTableProductNameRow'

export const renderCreatedByNameRowContent = ({
  firstName,
  lastName,
  email,
}: OrdersTableCreatedByNameRowProps) => (
  <OrdersTableCreatedByNameRow firstName={firstName} lastName={lastName} email={email} />
)

const renderActionsDropdown = (order: Order) => <OrdersTableRowActions order={order} />

const renderId = (orderId: string) => <div className="font-medium">{orderId}</div>

const renderOrderValue = ({ orderValue }: { orderValue?: string | null }) => (
  <div className="whitespace-nowrap">{orderValue}</div>
)

const defaultMeta = { className: 'align-top' }

const OrdersTable = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const formatDate = useDateFormatter(language, dateFormatTypes.datetime)

  const formatMoneyAmount = useMoneyFormatter(language)

  const getOrderStatusBadge = useOrderStatusBadge()

  const ordersQuery = useGetOrdersQuery()

  const columns: Columns<Order> = [
    {
      id: 'humanReadableId',
      accessorFn: row => row.humanReadableId,
      meta: { ...defaultMeta },
      header: t('rsp:pages.orders.list.orderId'),
      cell: ({ row }) => renderId(row.original.humanReadableId ?? ''),
      minSize: 100,
    },
    {
      accessorKey: 'organizationName',
      header: t('rsp:pages.orders.list.organizationName'),
      meta: { className: 'align-top' },
      cell: ({ row }) => row.original.organizationName,
      minSize: 280,
    },
    {
      accessorKey: 'createdOn',
      header: t('rsp:pages.orders.list.createdOn'),
      meta: { ...defaultMeta },
      cell: ({ row }) => formatDate(row.original.createdOn),
      minSize: 150,
    },
    {
      accessorKey: 'orderValue',
      meta: { ...defaultMeta },
      header: t('rsp:pages.orders.list.orderValue'),
      cell: ({ row }) =>
        renderOrderValue({
          orderValue: row.original.orderValue ? formatMoneyAmount(row.original.orderValue) : null,
        }),
    },
    {
      accessorKey: 'orderStatus',
      meta: { className: `${defaultMeta.className} text-center` },
      header: t('rsp:pages.orders.list.status'),
      cell: ({ row }) => getOrderStatusBadge(row.original.orderStatus),
      minSize: 20,
    },
    {
      accessorKey: 'actions',
      meta: { ...defaultMeta },
      header: String(),
      enableSorting: false,
      cell: ({ row }) => renderActionsDropdown(row.original),
      minSize: 20,
    },
  ]

  return (
    <DataTable
      dataTestId="tableOrders"
      columns={columns}
      data={ordersQuery?.data}
      filterableFields={['humanReadableId', 'organizationName']}
      filterPlaceholder={t('rsp:pages.orders.list.filterPlaceholder')}
      fieldDescription={t('rsp:pages.orders.list.searchFieldDescription')}
    />
  )
}

export default OrdersTable
