import { useTranslation } from 'react-i18next'

import { BillingCycle, CommitmentTermType } from '@sherweb/core/openapi-generated/index.defs'

import { Typography } from '@sherweb/core/components/Typography'

import {
  useBillingCycleSummaryTranslation,
  useCommitmentTermSummaryTranslation,
} from '@ssp/modules/shop/core/shop.helpers'

import { useGetFormattedTotalAmount } from '../hooks/useGetFormattedTotalAmount'
import { useGetOffersByCommitmentTerm } from '../hooks/useGetOffersByCommitmentTerm'

export const ShopDetailTotalPrice = () => {
  const { t } = useTranslation()

  const getBillingCycleSummaryTranslation = useBillingCycleSummaryTranslation()

  const getCommitmentTermSummaryTranslation = useCommitmentTermSummaryTranslation()

  const { selectedOffer } = useGetOffersByCommitmentTerm()

  const { formattedTotalAmount } = useGetFormattedTotalAmount()

  const textTranslationWithCommitmentTermAndBillingCycle = {
    [t('ssp:pages.shop.detail.commitmentSummary.summary', {
      billingCycle: getBillingCycleSummaryTranslation(selectedOffer?.billingCycle),
      commitmentTerm: getCommitmentTermSummaryTranslation(selectedOffer?.commitmentTermType),
    })]:
      selectedOffer?.billingCycle !== BillingCycle.Unknown &&
      selectedOffer?.commitmentTermType !== CommitmentTermType.Unknown,
    [t('ssp:pages.shop.detail.commitmentSummary.summaryWithoutBillingCycle', {
      commitmentTerm: getCommitmentTermSummaryTranslation(selectedOffer?.commitmentTermType),
    })]:
      selectedOffer?.billingCycle === BillingCycle.Unknown &&
      selectedOffer?.commitmentTermType !== CommitmentTermType.Unknown,
    [t('ssp:pages.shop.detail.commitmentSummary.summaryWithoutCommitment', {
      billingCycle: getBillingCycleSummaryTranslation(selectedOffer?.billingCycle),
    })]:
      selectedOffer?.billingCycle !== BillingCycle.Unknown &&
      selectedOffer?.commitmentTermType === CommitmentTermType.Unknown,
    [t('ssp:pages.shop.detail.commitmentSummary.summaryWithoutBillingCycleAndCommitment')]:
      selectedOffer?.billingCycle === BillingCycle.Unknown &&
      selectedOffer?.commitmentTermType === CommitmentTermType.Unknown,
  }

  return (
    <>
      <Typography variant="body1" weight="medium" className="mb-2 text-wrap">
        {t('ssp:pages.shop.detail.total')}
      </Typography>
      <Typography
        variant="heading5"
        weight="bold"
        as="div"
        className="duration-1500 transition ease-out"
      >
        {formattedTotalAmount}
      </Typography>
      <Typography variant="body2" colors="light" weight="default" as="div">
        {Object.keys(textTranslationWithCommitmentTermAndBillingCycle).find(
          option => textTranslationWithCommitmentTermAndBillingCycle?.[option]
        )}
      </Typography>
    </>
  )
}
