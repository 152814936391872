/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {
  /** only in axios interceptor config*/
  loading?: boolean;
  showError?: boolean;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
  /** only in axios interceptor config*/
  loading: boolean;
  showError: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.showError,
    ...options,
    method,
    url
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class SubscriptionQueryResult {
  /**  */
  'id': string;

  /**  */
  'sku'?: string;

  /**  */
  'productName'?: string;

  /**  */
  'description'?: string;

  /**  */
  'commitmentType'?: CommitmentTermType;

  /**  */
  'billingCycle'?: BillingCycle;

  /**  */
  'status'?: Status;

  /**  */
  'commitmentEndDate'?: Date;

  /**  */
  'renewalDate'?: Date;

  /**  */
  'scheduledQuantity'?: number;

  /**  */
  'quantity'?: number;

  /**  */
  'organizationId'?: string;

  /**  */
  'price'?: Money;

  /**  */
  'lastPendingOrFailedOrder'?: Order;

  /**  */
  'orderApprovalSettings'?: OrderApprovalSettings;

  /**  */
  'humanReadableId'?: string;

  /**  */
  'committedMinimalQuantity'?: number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['sku'] = data['sku'];
    this['productName'] = data['productName'];
    this['description'] = data['description'];
    this['commitmentType'] = data['commitmentType'];
    this['billingCycle'] = data['billingCycle'];
    this['status'] = data['status'];
    this['commitmentEndDate'] = data['commitmentEndDate'];
    this['renewalDate'] = data['renewalDate'];
    this['scheduledQuantity'] = data['scheduledQuantity'];
    this['quantity'] = data['quantity'];
    this['organizationId'] = data['organizationId'];
    this['price'] = data['price'];
    this['lastPendingOrFailedOrder'] = data['lastPendingOrFailedOrder'];
    this['orderApprovalSettings'] = data['orderApprovalSettings'];
    this['humanReadableId'] = data['humanReadableId'];
    this['committedMinimalQuantity'] = data['committedMinimalQuantity'];
  }
}

export class Money {
  /**  */
  'amount': number;

  /**  */
  'currency': Currency;

  constructor(data: undefined | any = {}) {
    this['amount'] = data['amount'];
    this['currency'] = data['currency'];
  }
}

export class Order {
  /**  */
  'orderId': string;

  /**  */
  'status': OrderStatus;

  /**  */
  'createdAt': Date;

  /**  */
  'updatedAt': Date;

  /**  */
  'completedAt'?: Date;

  /**  */
  'quantity': number;

  /**  */
  'error'?: OrderError;

  /**  */
  'initialQuantity': number;

  /**  */
  'decisionNote'?: string;

  /**  */
  'humanReadableId'?: string;

  constructor(data: undefined | any = {}) {
    this['orderId'] = data['orderId'];
    this['status'] = data['status'];
    this['createdAt'] = data['createdAt'];
    this['updatedAt'] = data['updatedAt'];
    this['completedAt'] = data['completedAt'];
    this['quantity'] = data['quantity'];
    this['error'] = data['error'];
    this['initialQuantity'] = data['initialQuantity'];
    this['decisionNote'] = data['decisionNote'];
    this['humanReadableId'] = data['humanReadableId'];
  }
}

export class OrderError {
  /**  */
  'content'?: string;

  /**  */
  'dismissed': boolean;

  constructor(data: undefined | any = {}) {
    this['content'] = data['content'];
    this['dismissed'] = data['dismissed'];
  }
}

export class OrderApprovalSettings {
  /**  */
  'isOrderApprovalRequired': boolean;

  constructor(data: undefined | any = {}) {
    this['isOrderApprovalRequired'] = data['isOrderApprovalRequired'];
  }
}

export class ProblemDetails {
  constructor(data: undefined | any = {}) {}
}

export class ErrorDetails {
  /**  */
  'code'?: string;

  /**  */
  'message'?: string;

  /**  */
  'property'?: string;

  /**  */
  'type': ErrorType;

  constructor(data: undefined | any = {}) {
    this['code'] = data['code'];
    this['message'] = data['message'];
    this['property'] = data['property'];
    this['type'] = data['type'];
  }
}

export class ProblemDetails2 {
  /**  */
  'type'?: string;

  /**  */
  'title'?: string;

  /**  */
  'status'?: number;

  /**  */
  'detail'?: string;

  /**  */
  'instance'?: string;

  constructor(data: undefined | any = {}) {
    this['type'] = data['type'];
    this['title'] = data['title'];
    this['status'] = data['status'];
    this['detail'] = data['detail'];
    this['instance'] = data['instance'];
  }
}

export class CreateAmendmentOrderCommand {
  /**  */
  'organizationId': string;

  /**  */
  'scheduledDate'?: Date;

  /**  */
  'orderLines'?: CreateOrderLineDto[];

  constructor(data: undefined | any = {}) {
    this['organizationId'] = data['organizationId'];
    this['scheduledDate'] = data['scheduledDate'];
    this['orderLines'] = data['orderLines'];
  }
}

export class CreateOrderLineDto {
  /**  */
  'quantity': number;

  /**  */
  'subscriptionId': string;

  constructor(data: undefined | any = {}) {
    this['quantity'] = data['quantity'];
    this['subscriptionId'] = data['subscriptionId'];
  }
}

export class DismissAmendmentOrderErrorCommand {
  /**  */
  'organizationId': string;

  /**  */
  'orderLines'?: OrderLineDto[];

  constructor(data: undefined | any = {}) {
    this['organizationId'] = data['organizationId'];
    this['orderLines'] = data['orderLines'];
  }
}

export class OrderLineDto {
  /**  */
  'orderId': string;

  /**  */
  'subscriptionId': string;

  constructor(data: undefined | any = {}) {
    this['orderId'] = data['orderId'];
    this['subscriptionId'] = data['subscriptionId'];
  }
}

export class ProcessOrderCommand {
  /**  */
  'organizationId': string;

  /**  */
  'decision'?: ProcessOrderDecision;

  /**  */
  'decisionNote'?: string;

  /**  */
  'orderIds'?: string[];

  constructor(data: undefined | any = {}) {
    this['organizationId'] = data['organizationId'];
    this['decision'] = data['decision'];
    this['decisionNote'] = data['decisionNote'];
    this['orderIds'] = data['orderIds'];
  }
}

export class SubscriberConnectionDataQueryResult {
  /**  */
  'subscriberId'?: string;

  /**  */
  'appId'?: string;

  /**  */
  'connectionHash'?: string;

  constructor(data: undefined | any = {}) {
    this['subscriberId'] = data['subscriberId'];
    this['appId'] = data['appId'];
    this['connectionHash'] = data['connectionHash'];
  }
}

export class WebhookValidationResult {
  /**  */
  'isValid': boolean;

  constructor(data: undefined | any = {}) {
    this['isValid'] = data['isValid'];
  }
}

export class WebhookValidCommand {
  /**  */
  'webhookUrl'?: string;

  constructor(data: undefined | any = {}) {
    this['webhookUrl'] = data['webhookUrl'];
  }
}

export class LicensesByVendorQueryResult {
  /**  */
  'vendorName'?: string;

  /**  */
  'accessible': boolean;

  /**  */
  'lastSyncedOn': Date;

  /**  */
  'licenses'?: LicenseQueryResult[];

  constructor(data: undefined | any = {}) {
    this['vendorName'] = data['vendorName'];
    this['accessible'] = data['accessible'];
    this['lastSyncedOn'] = data['lastSyncedOn'];
    this['licenses'] = data['licenses'];
  }
}

export class LicenseQueryResult {
  /**  */
  'id': string;

  /**  */
  'productName'?: string;

  /**  */
  'availableQuantity': number;

  /**  */
  'numberUsers': number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['productName'] = data['productName'];
    this['availableQuantity'] = data['availableQuantity'];
    this['numberUsers'] = data['numberUsers'];
  }
}

export class SubscribersByVendorQueryResult {
  /**  */
  'vendorName'?: string;

  /**  */
  'domains'?: VendorDomainResult[];

  /**  */
  'subscribers'?: SubscriberQueryResult[];

  constructor(data: undefined | any = {}) {
    this['vendorName'] = data['vendorName'];
    this['domains'] = data['domains'];
    this['subscribers'] = data['subscribers'];
  }
}

export class VendorDomainResult {
  /**  */
  'domain'?: string;

  /**  */
  'isDefault': boolean;

  constructor(data: undefined | any = {}) {
    this['domain'] = data['domain'];
    this['isDefault'] = data['isDefault'];
  }
}

export class SubscriberQueryResult {
  /**  */
  'id'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'email'?: string;

  /**  */
  'type': SubscriberType;

  /**  */
  'assignedLicenses'?: string[];

  /**  */
  'licenseUpdates'?: LicenseUpdateDto[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['email'] = data['email'];
    this['type'] = data['type'];
    this['assignedLicenses'] = data['assignedLicenses'];
    this['licenseUpdates'] = data['licenseUpdates'];
  }
}

export class LicenseUpdateDto {
  /**  */
  'licenseId': string;

  /**  */
  'licenseUpdateId'?: string;

  /**  */
  'updateStatus': LicenseUpdateStatus;

  constructor(data: undefined | any = {}) {
    this['licenseId'] = data['licenseId'];
    this['licenseUpdateId'] = data['licenseUpdateId'];
    this['updateStatus'] = data['updateStatus'];
  }
}

export class UsedLicenseStatistics {
  /**  */
  'currentUserLicenses'?: string[];

  /**  */
  'currentUserLicensesCount': number;

  /**  */
  'isRecalculating': boolean;

  constructor(data: undefined | any = {}) {
    this['currentUserLicenses'] = data['currentUserLicenses'];
    this['currentUserLicensesCount'] = data['currentUserLicensesCount'];
    this['isRecalculating'] = data['isRecalculating'];
  }
}

export class UnusedLicensesStatistics {
  /**  */
  'unusedLicenses'?: UnusedLicense[];

  /**  */
  'unusedLicensesCount': number;

  /**  */
  'isRecalculating': boolean;

  constructor(data: undefined | any = {}) {
    this['unusedLicenses'] = data['unusedLicenses'];
    this['unusedLicensesCount'] = data['unusedLicensesCount'];
    this['isRecalculating'] = data['isRecalculating'];
  }
}

export class UnusedLicense {
  /**  */
  'name'?: string;

  /**  */
  'unusedLicenseCount': number;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['unusedLicenseCount'] = data['unusedLicenseCount'];
  }
}

export class UpdateLicenseSubscribersCommand {
  /**  */
  'licenseId': string;

  /**  */
  'subscriberIdsOrUsersToBeAdded'?: SubscriberUserDto[];

  /**  */
  'subscriberIdsToBeRemoved'?: string[];

  constructor(data: undefined | any = {}) {
    this['licenseId'] = data['licenseId'];
    this['subscriberIdsOrUsersToBeAdded'] = data['subscriberIdsOrUsersToBeAdded'];
    this['subscriberIdsToBeRemoved'] = data['subscriberIdsToBeRemoved'];
  }
}

export class SubscriberUserDto {
  /**  */
  'id'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'email'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['email'] = data['email'];
  }
}

export class ErroneousLicenseUpdateDto {
  /**  */
  'productName'?: string;

  /**  */
  'subscriberName'?: string;

  /**  */
  'licenseUpdateId': string;

  constructor(data: undefined | any = {}) {
    this['productName'] = data['productName'];
    this['subscriberName'] = data['subscriberName'];
    this['licenseUpdateId'] = data['licenseUpdateId'];
  }
}

export class OrganizationOrderQueryResult {
  /**  */
  'orderId': string;

  /**  */
  'productName'?: string;

  /**  */
  'createdOn': Date;

  /**  */
  'type': OrderType;

  /**  */
  'initialQuantity': number;

  /**  */
  'requestedQuantity': number;

  /**  */
  'orderStatus': OrderStatus;

  /**  */
  'humanReadableId'?: string;

  constructor(data: undefined | any = {}) {
    this['orderId'] = data['orderId'];
    this['productName'] = data['productName'];
    this['createdOn'] = data['createdOn'];
    this['type'] = data['type'];
    this['initialQuantity'] = data['initialQuantity'];
    this['requestedQuantity'] = data['requestedQuantity'];
    this['orderStatus'] = data['orderStatus'];
    this['humanReadableId'] = data['humanReadableId'];
  }
}

export class CreatePurchaseOrderCommand {
  /**  */
  'serviceProviderId': string;

  /**  */
  'scheduledDate'?: Date;

  /**  */
  'orderItems'?: CreateOrderItemDto[];

  constructor(data: undefined | any = {}) {
    this['serviceProviderId'] = data['serviceProviderId'];
    this['scheduledDate'] = data['scheduledDate'];
    this['orderItems'] = data['orderItems'];
  }
}

export class CreateOrderItemDto {
  /**  */
  'sku'?: string;

  /**  */
  'productName'?: string;

  /**  */
  'vendorId': string;

  /**  */
  'vendorName'?: string;

  /**  */
  'quantity': number;

  /**  */
  'totalPrice'?: Money;

  constructor(data: undefined | any = {}) {
    this['sku'] = data['sku'];
    this['productName'] = data['productName'];
    this['vendorId'] = data['vendorId'];
    this['vendorName'] = data['vendorName'];
    this['quantity'] = data['quantity'];
    this['totalPrice'] = data['totalPrice'];
  }
}

export class DetailedOrganizationResult {
  /**  */
  'organizationId': string;

  /**  */
  'organizationName'?: string;

  /**  */
  'uniqueName'?: string;

  /**  */
  'userCount': number;

  /**  */
  'isSuspended': boolean;

  /**  */
  'settings'?: DetailedOrganizationSettings;

  constructor(data: undefined | any = {}) {
    this['organizationId'] = data['organizationId'];
    this['organizationName'] = data['organizationName'];
    this['uniqueName'] = data['uniqueName'];
    this['userCount'] = data['userCount'];
    this['isSuspended'] = data['isSuspended'];
    this['settings'] = data['settings'];
  }
}

export class DetailedOrganizationSettings {
  /**  */
  'showHelpDesk': boolean;

  /**  */
  'showSubscriptions': boolean;

  /**  */
  'showUsers': boolean;

  /**  */
  'showLicenses': boolean;

  /**  */
  'showBilling': boolean;

  /**  */
  'orgAdminCanCreateUsers': boolean;

  /**  */
  'isPriceVisible': boolean;

  /**  */
  'isOrderApprovalRequired': boolean;

  /**  */
  'usesDefaultSettings': boolean;

  /**  */
  'allowSubscriptionCancellation': boolean;

  constructor(data: undefined | any = {}) {
    this['showHelpDesk'] = data['showHelpDesk'];
    this['showSubscriptions'] = data['showSubscriptions'];
    this['showUsers'] = data['showUsers'];
    this['showLicenses'] = data['showLicenses'];
    this['showBilling'] = data['showBilling'];
    this['orgAdminCanCreateUsers'] = data['orgAdminCanCreateUsers'];
    this['isPriceVisible'] = data['isPriceVisible'];
    this['isOrderApprovalRequired'] = data['isOrderApprovalRequired'];
    this['usesDefaultSettings'] = data['usesDefaultSettings'];
    this['allowSubscriptionCancellation'] = data['allowSubscriptionCancellation'];
  }
}

export class SetOrganizationSettingsCommand {
  /**  */
  'showHelpDesk': boolean;

  /**  */
  'showSubscriptions': boolean;

  /**  */
  'showUsers': boolean;

  /**  */
  'showLicenses': boolean;

  /**  */
  'showBilling': boolean;

  /**  */
  'orgAdminCanCreateUsers': boolean;

  /**  */
  'isPriceVisible': boolean;

  /**  */
  'allowSubscriptionCancellation': boolean;

  /**  */
  'isOrderApprovalRequired': boolean;

  constructor(data: undefined | any = {}) {
    this['showHelpDesk'] = data['showHelpDesk'];
    this['showSubscriptions'] = data['showSubscriptions'];
    this['showUsers'] = data['showUsers'];
    this['showLicenses'] = data['showLicenses'];
    this['showBilling'] = data['showBilling'];
    this['orgAdminCanCreateUsers'] = data['orgAdminCanCreateUsers'];
    this['isPriceVisible'] = data['isPriceVisible'];
    this['allowSubscriptionCancellation'] = data['allowSubscriptionCancellation'];
    this['isOrderApprovalRequired'] = data['isOrderApprovalRequired'];
  }
}

export class CountResult {
  /**  */
  'count': number;

  /**  */
  'isRecalculating': boolean;

  constructor(data: undefined | any = {}) {
    this['count'] = data['count'];
    this['isRecalculating'] = data['isRecalculating'];
  }
}

export class UsersQueryResult {
  /**  */
  'id': string;

  /**  */
  'firstName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'email'?: string;

  /**  */
  'jobTitle'?: string;

  /**  */
  'status': UserStatus;

  /**  */
  'isVerified': boolean;

  /**  */
  'createdAt': Date;

  /**  */
  'updatedAt'?: Date;

  /**  */
  'lastSeen'?: Date;

  /**  */
  'roles'?: RoleQueryResult[];

  /**  */
  'preferredCulture'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['email'] = data['email'];
    this['jobTitle'] = data['jobTitle'];
    this['status'] = data['status'];
    this['isVerified'] = data['isVerified'];
    this['createdAt'] = data['createdAt'];
    this['updatedAt'] = data['updatedAt'];
    this['lastSeen'] = data['lastSeen'];
    this['roles'] = data['roles'];
    this['preferredCulture'] = data['preferredCulture'];
  }
}

export class RoleQueryResult {
  /**  */
  'id': string;

  /**  */
  'userRole': UserRole;

  /**  */
  'organizationId': string;

  /**  */
  'resellerId': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['userRole'] = data['userRole'];
    this['organizationId'] = data['organizationId'];
    this['resellerId'] = data['resellerId'];
  }
}

export class UserWithLicensesQueryResult {
  /**  */
  'id': string;

  /**  */
  'firstName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'email'?: string;

  /**  */
  'jobTitle'?: string;

  /**  */
  'status': UserStatus;

  /**  */
  'isVerified': boolean;

  /**  */
  'createdAt': Date;

  /**  */
  'updatedAt'?: Date;

  /**  */
  'lastSeen'?: Date;

  /**  */
  'roles'?: RoleQueryResult[];

  /**  */
  'preferredCulture'?: string;

  /**  */
  'licenses'?: UserLicenseQueryResult[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['email'] = data['email'];
    this['jobTitle'] = data['jobTitle'];
    this['status'] = data['status'];
    this['isVerified'] = data['isVerified'];
    this['createdAt'] = data['createdAt'];
    this['updatedAt'] = data['updatedAt'];
    this['lastSeen'] = data['lastSeen'];
    this['roles'] = data['roles'];
    this['preferredCulture'] = data['preferredCulture'];
    this['licenses'] = data['licenses'];
  }
}

export class UserLicenseQueryResult {
  /**  */
  'id': string;

  /**  */
  'productName'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['productName'] = data['productName'];
  }
}

export class UpdateUserCommand {
  /**  */
  'firstName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'jobTitle'?: string;

  /**  */
  'preferredCulture'?: string;

  /**  */
  'role': UserRole;

  constructor(data: undefined | any = {}) {
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['jobTitle'] = data['jobTitle'];
    this['preferredCulture'] = data['preferredCulture'];
    this['role'] = data['role'];
  }
}

export class PortalMetadataResponse {
  /**  */
  'resellerName'?: string;

  /**  */
  'name'?: string;

  /**  */
  'shortName'?: string;

  /**  */
  'resellerHostName'?: string;

  /**  */
  'images'?: PortalMetadataImage[];

  /**  */
  'theme': Theme;

  /**  */
  'themeColor'?: string;

  constructor(data: undefined | any = {}) {
    this['resellerName'] = data['resellerName'];
    this['name'] = data['name'];
    this['shortName'] = data['shortName'];
    this['resellerHostName'] = data['resellerHostName'];
    this['images'] = data['images'];
    this['theme'] = data['theme'];
    this['themeColor'] = data['themeColor'];
  }
}

export class PortalMetadataImage {
  /**  */
  'url'?: string;

  /**  */
  'size'?: string;

  /**  */
  'type': ImageType;

  constructor(data: undefined | any = {}) {
    this['url'] = data['url'];
    this['size'] = data['size'];
    this['type'] = data['type'];
  }
}

export class PortalSettingsQueryResult {
  /**  */
  'theme': Theme;

  /**  */
  'hostName'?: string;

  /**  */
  'logoHash': number;

  /**  */
  'images'?: ImageResultDto[];

  constructor(data: undefined | any = {}) {
    this['theme'] = data['theme'];
    this['hostName'] = data['hostName'];
    this['logoHash'] = data['logoHash'];
    this['images'] = data['images'];
  }
}

export class ImageResultDto {
  /**  */
  'type': ImageType;

  /**  */
  'url'?: string;

  /**  */
  'size'?: string;

  constructor(data: undefined | any = {}) {
    this['type'] = data['type'];
    this['url'] = data['url'];
    this['size'] = data['size'];
  }
}

export class ResellersQueryResult {
  /**  */
  'id': string;

  /**  */
  'name'?: string;

  /**  */
  'isOnboarded': boolean;

  /**  */
  'portalSettings'?: PortalSettingsQueryResult;

  /**  */
  'resellerSettings'?: ResellerSettingsQueryResult;

  /**  */
  'supportInformation'?: SupportInformationQueryResult;

  /**  */
  'orderSettings'?: OrderSettingsQueryResult;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['isOnboarded'] = data['isOnboarded'];
    this['portalSettings'] = data['portalSettings'];
    this['resellerSettings'] = data['resellerSettings'];
    this['supportInformation'] = data['supportInformation'];
    this['orderSettings'] = data['orderSettings'];
  }
}

export class ResellerSettingsQueryResult {
  /**  */
  'priceVisible': boolean;

  /**  */
  'isSubresellerShown': boolean;

  /**  */
  'showSubscriptions': boolean;

  /**  */
  'showUsers': boolean;

  /**  */
  'showLicenses': boolean;

  /**  */
  'showBilling': boolean;

  /**  */
  'orgAdminCanCreateUsers': boolean;

  /**  */
  'allowSubscriptionCancellation': boolean;

  constructor(data: undefined | any = {}) {
    this['priceVisible'] = data['priceVisible'];
    this['isSubresellerShown'] = data['isSubresellerShown'];
    this['showSubscriptions'] = data['showSubscriptions'];
    this['showUsers'] = data['showUsers'];
    this['showLicenses'] = data['showLicenses'];
    this['showBilling'] = data['showBilling'];
    this['orgAdminCanCreateUsers'] = data['orgAdminCanCreateUsers'];
    this['allowSubscriptionCancellation'] = data['allowSubscriptionCancellation'];
  }
}

export class SupportInformationQueryResult {
  /**  */
  'address'?: string;

  /**  */
  'email'?: string;

  /**  */
  'phoneNumber'?: string;

  constructor(data: undefined | any = {}) {
    this['address'] = data['address'];
    this['email'] = data['email'];
    this['phoneNumber'] = data['phoneNumber'];
  }
}

export class OrderSettingsQueryResult {
  /**  */
  'isOrderApprovalRequired': boolean;

  /**  */
  'teamsNotificationsEnabled': boolean;

  /**  */
  'teamsIncomingWebhook'?: string;

  /**  */
  'emailNotificationsEnabled': boolean;

  /**  */
  'emailRecipientsList'?: string[];

  constructor(data: undefined | any = {}) {
    this['isOrderApprovalRequired'] = data['isOrderApprovalRequired'];
    this['teamsNotificationsEnabled'] = data['teamsNotificationsEnabled'];
    this['teamsIncomingWebhook'] = data['teamsIncomingWebhook'];
    this['emailNotificationsEnabled'] = data['emailNotificationsEnabled'];
    this['emailRecipientsList'] = data['emailRecipientsList'];
  }
}

export class SetPortalSettingsCommand {
  /**  */
  'resellerId': string;

  /**  */
  'theme': Theme;

  constructor(data: undefined | any = {}) {
    this['resellerId'] = data['resellerId'];
    this['theme'] = data['theme'];
  }
}

export class Product {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'description'?: string;

  /**  */
  'serviceProviderId': string;

  /**  */
  'vendorId': string;

  /**  */
  'vendor': Vendor;

  /**  */
  'currency': Currency;

  /**  */
  'isProductAvailable': boolean;

  /**  */
  'categories'?: Category[];

  /**  */
  'offers'?: Offer[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['description'] = data['description'];
    this['serviceProviderId'] = data['serviceProviderId'];
    this['vendorId'] = data['vendorId'];
    this['vendor'] = data['vendor'];
    this['currency'] = data['currency'];
    this['isProductAvailable'] = data['isProductAvailable'];
    this['categories'] = data['categories'];
    this['offers'] = data['offers'];
  }
}

export class Offer {
  /**  */
  'sku'?: string;

  /**  */
  'billingCycle'?: BillingCycle;

  /**  */
  'commitmentTermType'?: CommitmentTermType;

  /**  */
  'pricingModelType'?: PricingModelType;

  /**  */
  'price': number;

  constructor(data: undefined | any = {}) {
    this['sku'] = data['sku'];
    this['billingCycle'] = data['billingCycle'];
    this['commitmentTermType'] = data['commitmentTermType'];
    this['pricingModelType'] = data['pricingModelType'];
    this['price'] = data['price'];
  }
}

export class SearchProductsResponse {
  /**  */
  'foundProducts': number;

  /**  */
  'page': number;

  /**  */
  'total': number;

  /**  */
  'products'?: SimpleProduct[];

  /**  */
  'facets'?: Facet[];

  constructor(data: undefined | any = {}) {
    this['foundProducts'] = data['foundProducts'];
    this['page'] = data['page'];
    this['total'] = data['total'];
    this['products'] = data['products'];
    this['facets'] = data['facets'];
  }
}

export class SimpleProduct {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'vendor': Vendor;

  /**  */
  'startingPrice'?: Money;

  /**  */
  'categories'?: Category[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['vendor'] = data['vendor'];
    this['startingPrice'] = data['startingPrice'];
    this['categories'] = data['categories'];
  }
}

export class Facet {
  /**  */
  'fieldName'?: string;

  /**  */
  'countsByValue'?: FacetCount[];

  constructor(data: undefined | any = {}) {
    this['fieldName'] = data['fieldName'];
    this['countsByValue'] = data['countsByValue'];
  }
}

export class FacetCount {
  /**  */
  'count': number;

  /**  */
  'highlighted'?: string;

  /**  */
  'value'?: string;

  constructor(data: undefined | any = {}) {
    this['count'] = data['count'];
    this['highlighted'] = data['highlighted'];
    this['value'] = data['value'];
  }
}

export class ProductSearchCommand {
  /**  */
  'organizationId': string;

  /**  */
  'page': number;

  /**  */
  'pageSize': number;

  /**  */
  'query'?: string;

  /**  */
  'sortBy': ProductSort;

  /**  */
  'filters'?: ProductSearchFilter;

  constructor(data: undefined | any = {}) {
    this['organizationId'] = data['organizationId'];
    this['page'] = data['page'];
    this['pageSize'] = data['pageSize'];
    this['query'] = data['query'];
    this['sortBy'] = data['sortBy'];
    this['filters'] = data['filters'];
  }
}

export class ProductSearchFilter {
  /**  */
  'billingCycleFilter'?: BillingCycle[];

  /**  */
  'commitmentTermFilter'?: CommitmentTermType[];

  /**  */
  'vendorFilter'?: Vendor[];

  constructor(data: undefined | any = {}) {
    this['billingCycleFilter'] = data['billingCycleFilter'];
    this['commitmentTermFilter'] = data['commitmentTermFilter'];
    this['vendorFilter'] = data['vendorFilter'];
  }
}

export class OrdersQueryResult {
  /**  */
  'orderId': string;

  /**  */
  'orderType': OrderType;

  /**  */
  'createdAt': Date;

  /**  */
  'organizationName'?: string;

  /**  */
  'userEmail'?: string;

  /**  */
  'userFirstName'?: string;

  /**  */
  'userLastName'?: string;

  /**  */
  'orderStatus': OrderStatus;

  /**  */
  'organizationUniqueName'?: string;

  /**  */
  'organizationId': string;

  /**  */
  'decisionNote'?: string;

  /**  */
  'humanReadableId'?: string;

  /**  */
  'errorResult'?: OrderErrorResult;

  /**  */
  'value'?: Money;

  /**  */
  'items'?: OrdersLineQueryResult[];

  constructor(data: undefined | any = {}) {
    this['orderId'] = data['orderId'];
    this['orderType'] = data['orderType'];
    this['createdAt'] = data['createdAt'];
    this['organizationName'] = data['organizationName'];
    this['userEmail'] = data['userEmail'];
    this['userFirstName'] = data['userFirstName'];
    this['userLastName'] = data['userLastName'];
    this['orderStatus'] = data['orderStatus'];
    this['organizationUniqueName'] = data['organizationUniqueName'];
    this['organizationId'] = data['organizationId'];
    this['decisionNote'] = data['decisionNote'];
    this['humanReadableId'] = data['humanReadableId'];
    this['errorResult'] = data['errorResult'];
    this['value'] = data['value'];
    this['items'] = data['items'];
  }
}

export class OrderErrorResult {
  /**  */
  'detail'?: string;

  /**  */
  'title'?: string;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['detail'] = data['detail'];
    this['title'] = data['title'];
    this['message'] = data['message'];
  }
}

export class OrdersLineQueryResult {
  /**  */
  'quantity': number;

  /**  */
  'productName'?: string;

  /**  */
  'subscriptionId': string;

  /**  */
  'subscriptionStatus': string;

  /**  */
  'price'?: Money;

  constructor(data: undefined | any = {}) {
    this['quantity'] = data['quantity'];
    this['productName'] = data['productName'];
    this['subscriptionId'] = data['subscriptionId'];
    this['price'] = data['price'];
  }
}

export class OrganizationsQueryResult {
  /**  */
  'id': string;

  /**  */
  'uniqueName'?: string;

  /**  */
  'name'?: string;

  /**  */
  'subreseller'?: string;

  /**  */
  'isSuspended': boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['uniqueName'] = data['uniqueName'];
    this['name'] = data['name'];
    this['subreseller'] = data['subreseller'];
    this['isSuspended'] = data['isSuspended'];
  }
}

export class PaginatedResultsOfListOfDetailedOrganizationListResult {
  /**  */
  'pageIndex': number;

  /**  */
  'totalPages': number;

  /**  */
  'results'?: DetailedOrganizationListResult[];

  /**  */
  'hasPreviousPage': boolean;

  /**  */
  'hasNextPage': boolean;

  constructor(data: undefined | any = {}) {
    this['pageIndex'] = data['pageIndex'];
    this['totalPages'] = data['totalPages'];
    this['results'] = data['results'];
    this['hasPreviousPage'] = data['hasPreviousPage'];
    this['hasNextPage'] = data['hasNextPage'];
  }
}

export class DetailedOrganizationListResult {
  /**  */
  'organizationId': string;

  /**  */
  'organizationName'?: string;

  /**  */
  'uniqueName'?: string;

  /**  */
  'userCount': number;

  /**  */
  'isSuspended': boolean;

  /**  */
  'usesDefaultSettings': boolean;

  constructor(data: undefined | any = {}) {
    this['organizationId'] = data['organizationId'];
    this['organizationName'] = data['organizationName'];
    this['uniqueName'] = data['uniqueName'];
    this['userCount'] = data['userCount'];
    this['isSuspended'] = data['isSuspended'];
    this['usesDefaultSettings'] = data['usesDefaultSettings'];
  }
}

export class DetailedOrganizationsQuery {
  /**  */
  'page': number;

  /**  */
  'pageSize': number;

  /**  */
  'searchTerm'?: string;

  /**  */
  'sort'?: OrganizationSort;

  constructor(data: undefined | any = {}) {
    this['page'] = data['page'];
    this['pageSize'] = data['pageSize'];
    this['searchTerm'] = data['searchTerm'];
    this['sort'] = data['sort'];
  }
}

export class OrganizationSort {
  /**  */
  'field': OrganizationSortField;

  /**  */
  'ascending': boolean;

  constructor(data: undefined | any = {}) {
    this['field'] = data['field'];
    this['ascending'] = data['ascending'];
  }
}

export class SetSupportInformationCommand {
  /**  */
  'email'?: string;

  /**  */
  'phoneNumber'?: string;

  /**  */
  'address'?: string;

  constructor(data: undefined | any = {}) {
    this['email'] = data['email'];
    this['phoneNumber'] = data['phoneNumber'];
    this['address'] = data['address'];
  }
}

export class SetResellerSettingsCommand {
  /**  */
  'priceVisible': boolean;

  /**  */
  'isSubresellerShown': boolean;

  /**  */
  'showSubscriptions': boolean;

  /**  */
  'showUsers': boolean;

  /**  */
  'showLicenses': boolean;

  /**  */
  'showBilling': boolean;

  /**  */
  'orgAdminCanCreateUsers': boolean;

  /**  */
  'allowSubscriptionCancellation': boolean;

  constructor(data: undefined | any = {}) {
    this['priceVisible'] = data['priceVisible'];
    this['isSubresellerShown'] = data['isSubresellerShown'];
    this['showSubscriptions'] = data['showSubscriptions'];
    this['showUsers'] = data['showUsers'];
    this['showLicenses'] = data['showLicenses'];
    this['showBilling'] = data['showBilling'];
    this['orgAdminCanCreateUsers'] = data['orgAdminCanCreateUsers'];
    this['allowSubscriptionCancellation'] = data['allowSubscriptionCancellation'];
  }
}

export class SetOrderSettingsCommand {
  /**  */
  'isOrderApprovalRequired': boolean;

  /**  */
  'teamsNotificationsEnabled': boolean;

  /**  */
  'teamsIncomingWebhook'?: string;

  /**  */
  'emailNotificationsEnabled': boolean;

  /**  */
  'emailRecipientsList'?: string[];

  constructor(data: undefined | any = {}) {
    this['isOrderApprovalRequired'] = data['isOrderApprovalRequired'];
    this['teamsNotificationsEnabled'] = data['teamsNotificationsEnabled'];
    this['teamsIncomingWebhook'] = data['teamsIncomingWebhook'];
    this['emailNotificationsEnabled'] = data['emailNotificationsEnabled'];
    this['emailRecipientsList'] = data['emailRecipientsList'];
  }
}

export class EmailRecipientsQueryResult {
  /**  */
  'id': string;

  /**  */
  'email'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['email'] = data['email'];
  }
}

export class ServiceProviderConfigurationQueryResult {
  /**  */
  'serviceProviderId': string;

  /**  */
  'clientId'?: string;

  constructor(data: undefined | any = {}) {
    this['serviceProviderId'] = data['serviceProviderId'];
    this['clientId'] = data['clientId'];
  }
}

export class AssignServiceProviderCommand {
  /**  */
  'serviceProviderId': string;

  /**  */
  'publicApiKey'?: string;

  /**  */
  'privateApiKey'?: string;

  /**  */
  'subscriptionKey'?: string;

  constructor(data: undefined | any = {}) {
    this['serviceProviderId'] = data['serviceProviderId'];
    this['publicApiKey'] = data['publicApiKey'];
    this['privateApiKey'] = data['privateApiKey'];
    this['subscriptionKey'] = data['subscriptionKey'];
  }
}

export class ServiceProvidersQueryResult {
  /**  */
  'id': string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
  }
}

export class InviteUserCommand {
  /**  */
  'invitedUserEmailAddress'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'jobTitle'?: string;

  /**  */
  'role': UserRole;

  /**  */
  'organizationsIds'?: string[];

  /**  */
  'resellerId'?: string;

  /**  */
  'preferredCulture'?: string;

  constructor(data: undefined | any = {}) {
    this['invitedUserEmailAddress'] = data['invitedUserEmailAddress'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['jobTitle'] = data['jobTitle'];
    this['role'] = data['role'];
    this['organizationsIds'] = data['organizationsIds'];
    this['resellerId'] = data['resellerId'];
    this['preferredCulture'] = data['preferredCulture'];
  }
}

export class ReInviteUserCommand {
  /**  */
  'organizationId'?: string;

  constructor(data: undefined | any = {}) {
    this['organizationId'] = data['organizationId'];
  }
}

export class DeleteUserCommand {
  /**  */
  'userId': string;

  /**  */
  'organizationId': string;

  constructor(data: undefined | any = {}) {
    this['userId'] = data['userId'];
    this['organizationId'] = data['organizationId'];
  }
}

export class OrganizationIntegrationsQueryResult {
  /**  */
  'billing'?: OrganizationIntegrationQueryResult;

  /**  */
  'helpDesk'?: OrganizationIntegrationQueryResult;

  /**  */
  'serviceProvider'?: OrganizationIntegrationQueryResult;

  constructor(data: undefined | any = {}) {
    this['billing'] = data['billing'];
    this['helpDesk'] = data['helpDesk'];
    this['serviceProvider'] = data['serviceProvider'];
  }
}

export class OrganizationIntegrationQueryResult {
  /**  */
  'isEnabled': boolean;

  constructor(data: undefined | any = {}) {
    this['isEnabled'] = data['isEnabled'];
  }
}

export class IntegrationListQueryResult {
  /**  */
  'billing'?: IntegrationQueryResult[];

  /**  */
  'helpDesk'?: IntegrationQueryResult[];

  /**  */
  'serviceProvider'?: IntegrationQueryResult[];

  constructor(data: undefined | any = {}) {
    this['billing'] = data['billing'];
    this['helpDesk'] = data['helpDesk'];
    this['serviceProvider'] = data['serviceProvider'];
  }
}

export class IntegrationQueryResult {
  /**  */
  'id': string;

  /**  */
  'integrationType': IntegrationType;

  /**  */
  'configured': boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['integrationType'] = data['integrationType'];
    this['configured'] = data['configured'];
  }
}

export class OrganizationHelpDeskAccessQueryResult {
  /**  */
  'isPsaAvailable': boolean;

  constructor(data: undefined | any = {}) {
    this['isPsaAvailable'] = data['isPsaAvailable'];
  }
}

export class HelpDeskTicketQueryResult {
  /**  */
  'id'?: string;

  /**  */
  'subject'?: string;

  /**  */
  'statusId': string;

  /**  */
  'ticketTypeId': string;

  /**  */
  'createdAt': Date;

  /**  */
  'openedByName'?: string;

  /**  */
  'openedByEmail'?: string;

  /**  */
  'lastUpdated'?: Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['subject'] = data['subject'];
    this['statusId'] = data['statusId'];
    this['ticketTypeId'] = data['ticketTypeId'];
    this['createdAt'] = data['createdAt'];
    this['openedByName'] = data['openedByName'];
    this['openedByEmail'] = data['openedByEmail'];
    this['lastUpdated'] = data['lastUpdated'];
  }
}

export class CreateHelpDeskTicketCommand {
  /**  */
  'ticketTypeId': string;

  /**  */
  'title'?: string;

  /**  */
  'description'?: string;

  constructor(data: undefined | any = {}) {
    this['ticketTypeId'] = data['ticketTypeId'];
    this['title'] = data['title'];
    this['description'] = data['description'];
  }
}

export class HelpDeskTicketType {
  /**  */
  'id'?: string;

  /**  */
  'integrationId'?: string;

  /**  */
  'name'?: string;

  /**  */
  'configured': boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['integrationId'] = data['integrationId'];
    this['name'] = data['name'];
    this['configured'] = data['configured'];
  }
}

export class HelpDeskTicketStatus {
  /**  */
  'id': string;

  /**  */
  'name'?: string;

  /**  */
  'placeholder'?: string;

  /**  */
  'isRequired': boolean;

  /**  */
  'type': HelpDeskTicketStatusType;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['placeholder'] = data['placeholder'];
    this['isRequired'] = data['isRequired'];
    this['type'] = data['type'];
  }
}

export class HelpDeskTicketDetailsQueryResult {
  /**  */
  'id'?: string;

  /**  */
  'subject'?: string;

  /**  */
  'statusId': string;

  /**  */
  'ticketTypeId': string;

  /**  */
  'createdAt': Date;

  /**  */
  'openedByName'?: string;

  /**  */
  'openedByEmail'?: string;

  /**  */
  'lastUpdated'?: Date;

  /**  */
  'notes'?: HelpDeskTicketNoteQueryResult[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['subject'] = data['subject'];
    this['statusId'] = data['statusId'];
    this['ticketTypeId'] = data['ticketTypeId'];
    this['createdAt'] = data['createdAt'];
    this['openedByName'] = data['openedByName'];
    this['openedByEmail'] = data['openedByEmail'];
    this['lastUpdated'] = data['lastUpdated'];
    this['notes'] = data['notes'];
  }
}

export class HelpDeskTicketNoteQueryResult {
  /**  */
  'id'?: string;

  /**  */
  'createdByName'?: string;

  /**  */
  'createdByEmail'?: string;

  /**  */
  'createdDate': Date;

  /**  */
  'text'?: string;

  /**  */
  'isCurrentUserNote': boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['createdByName'] = data['createdByName'];
    this['createdByEmail'] = data['createdByEmail'];
    this['createdDate'] = data['createdDate'];
    this['text'] = data['text'];
    this['isCurrentUserNote'] = data['isCurrentUserNote'];
  }
}

export class CreateHelpDeskTicketNoteCommand {
  /**  */
  'text'?: string;

  constructor(data: undefined | any = {}) {
    this['text'] = data['text'];
  }
}

export class HelpDeskApiInformation {
  /**  */
  'integrationType': IntegrationType;

  /**  */
  'companyName'?: string;

  /**  */
  'enabled': boolean;

  /**  */
  'publicApiKey'?: string;

  /**  */
  'clientId': string;

  /**  */
  'url'?: string;

  constructor(data: undefined | any = {}) {
    this['integrationType'] = data['integrationType'];
    this['companyName'] = data['companyName'];
    this['enabled'] = data['enabled'];
    this['publicApiKey'] = data['publicApiKey'];
    this['clientId'] = data['clientId'];
    this['url'] = data['url'];
  }
}

export class SetHelpDeskApiInformationCommand {
  /**  */
  'enabled': boolean;

  /**  */
  'companyName'?: string;

  /**  */
  'publicApiKey'?: string;

  /**  */
  'privateApiKey'?: string;

  /**  */
  'clientId'?: string;

  /**  */
  'url'?: string;

  constructor(data: undefined | any = {}) {
    this['enabled'] = data['enabled'];
    this['companyName'] = data['companyName'];
    this['publicApiKey'] = data['publicApiKey'];
    this['privateApiKey'] = data['privateApiKey'];
    this['clientId'] = data['clientId'];
    this['url'] = data['url'];
  }
}

export class HelpDeskTicketStatusMapping {
  /**  */
  'ticketStatusMappingList'?: TicketStatusMapping[];

  /**  */
  'integrationStatuses'?: IntegrationStatus[];

  constructor(data: undefined | any = {}) {
    this['ticketStatusMappingList'] = data['ticketStatusMappingList'];
    this['integrationStatuses'] = data['integrationStatuses'];
  }
}

export class TicketStatusMapping {
  /**  */
  'ticketStatusId': string;

  /**  */
  'integrationTicketStatusId'?: string;

  constructor(data: undefined | any = {}) {
    this['ticketStatusId'] = data['ticketStatusId'];
    this['integrationTicketStatusId'] = data['integrationTicketStatusId'];
  }
}

export class IntegrationStatus {
  /**  */
  'integrationTicketStatusId'?: string;

  /**  */
  'integrationTicketStatusName'?: string;

  constructor(data: undefined | any = {}) {
    this['integrationTicketStatusId'] = data['integrationTicketStatusId'];
    this['integrationTicketStatusName'] = data['integrationTicketStatusName'];
  }
}

export class HelpDeskServiceBoard {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'active': boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['active'] = data['active'];
  }
}

export class SetHelpDeskBoardConfigurationCommand {
  /**  */
  'serviceBoardId'?: string;

  /**  */
  'ticketStatusMappings'?: SetHelpDeskMappingTicketStatus[];

  /**  */
  'ticketTypeMappings'?: string[];

  constructor(data: undefined | any = {}) {
    this['serviceBoardId'] = data['serviceBoardId'];
    this['ticketStatusMappings'] = data['ticketStatusMappings'];
    this['ticketTypeMappings'] = data['ticketTypeMappings'];
  }
}

export class SetHelpDeskMappingTicketStatus {
  /**  */
  'ticketStatusId': string;

  /**  */
  'integrationTicketStatusId'?: string;

  constructor(data: undefined | any = {}) {
    this['ticketStatusId'] = data['ticketStatusId'];
    this['integrationTicketStatusId'] = data['integrationTicketStatusId'];
  }
}

export class HelpDeskOrganization {
  /**  */
  'id': string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
  }
}

export class HelpDeskOrganizationMappingsResponseDto {
  /**  */
  'organizationMappingResults'?: HelpDeskOrganizationMappingResult[];

  constructor(data: undefined | any = {}) {
    this['organizationMappingResults'] = data['organizationMappingResults'];
  }
}

export class HelpDeskOrganizationMappingResult {
  /**  */
  'id': string;

  /**  */
  'name'?: string;

  /**  */
  'isMapped': boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['isMapped'] = data['isMapped'];
  }
}

export class SetHelpDeskOrganizationMappingsCommand {
  /**  */
  'setOrganizationMappings'?: SetHelpDeskOrganizationMapping[];

  constructor(data: undefined | any = {}) {
    this['setOrganizationMappings'] = data['setOrganizationMappings'];
  }
}

export class SetHelpDeskOrganizationMapping {
  /**  */
  'organizationId': string;

  /**  */
  'companyId'?: string;

  constructor(data: undefined | any = {}) {
    this['organizationId'] = data['organizationId'];
    this['companyId'] = data['companyId'];
  }
}

export enum CommitmentTermType {
  'Annual' = 'Annual',
  'Biennial' = 'Biennial',
  'Triennial' = 'Triennial',
  'Monthly' = 'Monthly',
  'Unknown' = 'Unknown'
}

export enum BillingCycle {
  'None' = 'None',
  'Daily' = 'Daily',
  'Weekly' = 'Weekly',
  'Monthly' = 'Monthly',
  'Yearly' = 'Yearly',
  'Unknown' = 'Unknown'
}

export enum Status {
  'New' = 'New',
  'Processing' = 'Processing',
  'Active' = 'Active',
  'Suspended' = 'Suspended',
  'Inactive' = 'Inactive'
}

export enum Currency {
  'Cad' = 'Cad',
  'Usd' = 'Usd',
  'Eur' = 'Eur',
  'Gbp' = 'Gbp'
}

export enum OrderStatus {
  'Completed' = 'Completed',
  'Pending' = 'Pending',
  'Failed' = 'Failed',
  'WaitingForApproval' = 'WaitingForApproval',
  'Rejected' = 'Rejected'
}

export enum ErrorType {
  'Validation' = 'Validation',
  'Business' = 'Business',
  'System' = 'System'
}

export enum ProcessOrderDecision {
  'Approve' = 'Approve',
  'Reject' = 'Reject'
}

export enum SubscriberType {
  'Subscriber' = 'Subscriber',
  'User' = 'User',
  'LicenseUpdate' = 'LicenseUpdate'
}

export enum LicenseUpdateStatus {
  'NotApplicable' = 'NotApplicable',
  'Pending' = 'Pending',
  'Failure' = 'Failure'
}

export enum OrderType {
  'Amendment' = 'Amendment',
  'Purchase' = 'Purchase'
}

export enum UserStatus {
  'Inactive' = 'Inactive',
  'Active' = 'Active',
  'InvitationSent' = 'InvitationSent'
}

export enum UserRole {
  'BasicUser' = 'BasicUser',
  'OrganizationAdmin' = 'OrganizationAdmin',
  'ResellerAdmin' = 'ResellerAdmin',
  'InternalAdmin' = 'InternalAdmin',
  'ResellerGuest' = 'ResellerGuest'
}

export enum ImageType {
  'Logo' = 'Logo',
  'Icon' = 'Icon',
  'Other' = 'Other'
}

export enum Theme {
  'Default' = 'Default',
  'Green' = 'Green',
  'Blue' = 'Blue'
}

export enum PortalType {
  'SSP' = 'SSP',
  'RSP' = 'RSP'
}

export enum Vendor {
  'Microsoft' = 'Microsoft',
  'Google' = 'Google'
}

export enum Category {
  'BusinessApps' = 'BusinessApps',
  'Infrastructure' = 'Infrastructure',
  'ManagedServices' = 'ManagedServices',
  'Productivity' = 'Productivity',
  'SecurityBackupCompliance' = 'SecurityBackupCompliance',
  'Voip' = 'Voip',
  'Unknown' = 'Unknown'
}

export enum PricingModelType {
  'Subscription' = 'Subscription',
  'OneTimePurchase' = 'OneTimePurchase',
  'Unknown' = 'Unknown'
}

export enum ProductSort {
  'ProductNameAscending' = 'ProductNameAscending',
  'ProductNameDescending' = 'ProductNameDescending',
  'PriceAscending' = 'PriceAscending',
  'PriceDescending' = 'PriceDescending'
}

export enum OrganizationSortField {
  'None' = 'None',
  'OrganizationName' = 'OrganizationName',
  'UsersCount' = 'UsersCount',
  'SettingsType' = 'SettingsType',
  'Status' = 'Status'
}

export enum IntegrationType {
  'None' = 'None',
  'Autotask' = 'Autotask',
  'ConnectWise' = 'ConnectWise',
  'Paypal' = 'Paypal',
  'Sherweb' = 'Sherweb'
}

export enum HelpDeskTicketStatusType {
  'Closed' = 'Closed',
  'New' = 'New',
  'Processing' = 'Processing',
  'Resolved' = 'Resolved',
  'WaitingForCustomer' = 'WaitingForCustomer'
}

export enum Permission {
  'SubscriptionList' = 'SubscriptionList',
  'SubscriptionPrices' = 'SubscriptionPrices',
  'SubscriptionDetails' = 'SubscriptionDetails',
  'SubscriptionEdit' = 'SubscriptionEdit',
  'SubscriptionCancel' = 'SubscriptionCancel',
  'OrderApproval' = 'OrderApproval',
  'UsersList' = 'UsersList',
  'UsersEdit' = 'UsersEdit',
  'UsersCreate' = 'UsersCreate',
  'UsersDelete' = 'UsersDelete',
  'UserActivity' = 'UserActivity',
  'UserSubscriptions' = 'UserSubscriptions',
  'UserTickets' = 'UserTickets',
  'DashboardAccess' = 'DashboardAccess',
  'DashboardEdit' = 'DashboardEdit',
  'DashboardAddWidget' = 'DashboardAddWidget',
  'DashboardDeleteWidget' = 'DashboardDeleteWidget',
  'ActivitiesUser' = 'ActivitiesUser',
  'ActivitiesUsers' = 'ActivitiesUsers',
  'ResellerAdmin' = 'ResellerAdmin',
  'LicenseList' = 'LicenseList',
  'LicenseDetails' = 'LicenseDetails',
  'LicenseAssign' = 'LicenseAssign',
  'IntegrationList' = 'IntegrationList',
  'IntegrationDetails' = 'IntegrationDetails',
  'IntegrationConfigure' = 'IntegrationConfigure',
  'HelpDeskList' = 'HelpDeskList',
  'HelpDeskDetails' = 'HelpDeskDetails',
  'HelpDeskEdit' = 'HelpDeskEdit',
  'AdminOrderList' = 'AdminOrderList',
  'OrderList' = 'OrderList',
  'AccessToSuspendedOrganization' = 'AccessToSuspendedOrganization',
  'MarketplaceProductList' = 'MarketplaceProductList',
  'MarketplaceProductDetail' = 'MarketplaceProductDetail',
  'MarketplaceProductEdit' = 'MarketplaceProductEdit'
}

export enum SspHubKeys {
  'RefreshOrganizationSubscriptions' = 'RefreshOrganizationSubscriptions',
  'LogOutUser' = 'LogOutUser',
  'RefreshLicenses' = 'RefreshLicenses',
  'RefreshOrganizations' = 'RefreshOrganizations',
  'RefreshPermissions' = 'RefreshPermissions',
  'Notify' = 'Notify',
  'RefreshOrders' = 'RefreshOrders',
  'RefreshAdminOrders' = 'RefreshAdminOrders',
  'AssignToOrganizationGroup' = 'AssignToOrganizationGroup',
  'AssignToResellerGroup' = 'AssignToResellerGroup'
}

export enum NotificationLevel {
  'Info' = 'Info',
  'Success' = 'Success',
  'Warning' = 'Warning',
  'Error' = 'Error'
}
