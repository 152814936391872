import { useTranslation } from 'react-i18next'

import { OrganizationOrderQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import DataTable from '@sherweb/core/components/DataTable'
import { Columns } from '@sherweb/core/components/DataTable/types'
import { dateFormatTypes, useDateFormatter } from '@sherweb/core/utils/date'

import { useOrders } from '@ssp/modules/orders'

import { useRenderOrderType, useRenderStatusBadge } from '../orders.helper'

const renderId = (orderId: string) => <div className="font-medium">{orderId}</div>

const defaultMeta = { className: 'align-top text-center' }

const OrdersTable = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { data: orders = [] } = useOrders()

  const renderStatusBadge = useRenderStatusBadge()

  const renderOrderType = useRenderOrderType()

  const formatDate = useDateFormatter(language, dateFormatTypes.datetime)

  const columns: Columns<OrganizationOrderQueryResult> = [
    {
      id: 'humanReadableId',
      accessorFn: row => row.humanReadableId,
      meta: { ...defaultMeta },
      header: t('ssp:pages.orders.list.id'),
      cell: ({ row }) => renderId(row.original.humanReadableId ?? ''),
    },
    {
      accessorKey: 'productName',
      header: t('ssp:pages.orders.list.productName'),
      meta: { className: 'align-top' },
      cell: ({ row }) => row.original.productName,
    },
    {
      accessorKey: 'createdOn',
      header: t('ssp:pages.orders.list.createdOn'),
      meta: { className: 'align-top' },
      cell: ({ row }) => formatDate(row.original.createdOn),
    },
    {
      id: 'type',
      accessorFn: row => row.type,
      meta: { ...defaultMeta },
      header: t('ssp:pages.orders.list.type'),
      cell: ({ row }) => renderOrderType(row.original.type),
    },
    {
      id: 'requestedQuantity',
      accessorFn: row => row.requestedQuantity,
      meta: { ...defaultMeta },
      header: t('ssp:pages.orders.list.quantity'),
      cell: ({ row }) => row.original.requestedQuantity,
    },
    {
      accessorKey: 'status',
      meta: { ...defaultMeta },
      header: t('ssp:pages.orders.list.status'),
      cell: ({ row }) => renderStatusBadge(row.original.orderStatus),
    },
  ]

  return (
    <DataTable
      dataTestId="tableOrders"
      columns={columns}
      data={orders}
      filterableFields={['humanReadableId', 'productName']}
      filterPlaceholder={t('ssp:pages.orders.filterPlaceholder')}
      fieldDescription={t('ssp:pages.orders.fieldDescription')}
    />
  )
}

export default OrdersTable
