import { useTranslation } from 'react-i18next'

import {
  Accordion,
  AccordionContent,
  AccordionContentList,
  AccordionContentListItem,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerSubContent,
} from '@sherweb/core/components/Accordion'
import { DataTableMobileHeader } from '@sherweb/core/components/DataTable/DataTableInputMobile'
import { useSetTableSearchParams } from '@sherweb/core/components/DataTable/hooks/useSetTableSearchParam'
import { dateFormatTypes, useDateFormatter } from '@sherweb/core/utils/date'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import { Order, useGetOrdersQuery } from '@rsp/modules/orders'
import { useOrderStatusBadge } from '@rsp/modules/orders/core/orders.helpers'

import { OrderActions } from './OrderActions'

const OrdersTableMobile = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const formatDate = useDateFormatter(language, dateFormatTypes.datetime)

  const ordersQuery = useGetOrdersQuery()

  const formatMoneyAmount = useMoneyFormatter(language)

  const getOrderStatusBadge = useOrderStatusBadge()

  const { search: searchText, onSetSearchParams } = useSetTableSearchParams()

  const filteredData = ordersQuery?.data?.filter((item: Order) => {
    return (
      (item.humanReadableId ?? '')?.toLowerCase().includes(searchText.toLowerCase()) ||
      item.organizationName?.toLowerCase().includes(searchText.toLowerCase())
    )
  })

  return (
    <div className="flex flex-col gap-4">
      <DataTableMobileHeader>
        <DataTableMobileHeader.Input
          placeholder={t('rsp:pages.orders.list.filterPlaceholder')}
          filteredText={searchText}
          setFilteredText={onSetSearchParams}
        />
      </DataTableMobileHeader>
      {filteredData?.map((order: Order) => {
        return (
          <Accordion key={order.id} type="single" collapsible>
            <AccordionItem value="item-1">
              <AccordionTrigger className="relative justify-between rounded-xl bg-white p-4 [&[data-state=open]>div>div]:hidden [&[data-state=open]]:rounded-b-none">
                {order.organizationName} - {order.humanReadableId}
                <AccordionTriggerSubContent>
                  {getOrderStatusBadge(order.orderStatus)}
                </AccordionTriggerSubContent>
              </AccordionTrigger>
              <AccordionContent>
                <AccordionContentList>
                  <AccordionContentListItem
                    label={t('rsp:pages.orders.list.organizationName')}
                    value={order?.organizationName}
                  />
                  <AccordionContentListItem
                    label={t('rsp:pages.orders.list.createdOn')}
                    value={formatDate(order?.createdOn)}
                  />
                  <AccordionContentListItem
                    label={t('rsp:pages.orders.list.orderValue')}
                    value={order.orderValue ? formatMoneyAmount(order.orderValue) : null}
                  />
                  <AccordionContentListItem
                    label={t('rsp:pages.orders.list.status')}
                    value={getOrderStatusBadge(order.orderStatus)}
                  />
                  <AccordionContentListItem
                    className="justify-end"
                    labelClassName="flex flex-wrap"
                    label={<OrderActions order={order} className="flex flex-wrap" />}
                  />
                </AccordionContentList>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )
      })}
    </div>
  )
}

export default OrdersTableMobile
