import { useTranslation } from 'react-i18next'

import {
  DetailedOrganizationListResult,
  OrganizationSortField,
} from '@sherweb/core/openapi-generated/index.defs'

import Card from '@sherweb/core/components/Card'
import DataTableServerPagination from '@sherweb/core/components/DataTable/DataTableServerPagination'
import { useServerPagination } from '@sherweb/core/components/DataTable/hooks/useServerPagination'
import { Columns } from '@sherweb/core/components/DataTable/types'
import { SkeletonTable } from '@sherweb/core/components/Skeleton'
import { isRequestLoading } from '@sherweb/core/modules/reactQuery'

import { useGetOrganizationsQuery } from '@rsp/modules/organizations'
import { getOrganizationsPaginationDefaultValues } from '@rsp/modules/organizations/core/organizations.helpers'

import { useRenderSettingsType, useRenderStatusBadge } from '../organizations.helper'
import { OrganizationsTableRowActions } from './components/OrganizationsTableRowActions'

const defaultMeta = { className: 'align-top text-center' }

const OrganizationsTable = () => {
  const { t } = useTranslation()

  const { options, setOptions, onSortBy } = useServerPagination<OrganizationSortField>(
    getOrganizationsPaginationDefaultValues()
  )

  const organizationsQuery = useGetOrganizationsQuery(options)

  const renderSettingsType = useRenderSettingsType()

  const renderStatusBadge = useRenderStatusBadge()

  const renderActionsDropdown = (organization: DetailedOrganizationListResult) => (
    <OrganizationsTableRowActions organization={organization} />
  )

  const columns: Columns<DetailedOrganizationListResult> = [
    {
      accessorKey: 'organizationName',
      meta: { className: 'align-top' },
      header: t('rsp:pages.organizations.list.name'),
      enableSorting: true,
      cell: ({ row }) => row.original.organizationName,
      minSize: 400,
    },
    {
      accessorKey: 'usersCount',
      header: t('rsp:pages.organizations.list.users'),
      enableSorting: true,
      meta: { ...defaultMeta },
      cell: ({ row }) => row.original.userCount,
    },
    {
      accessorKey: 'settingsType',
      meta: { ...defaultMeta },
      enableSorting: true,
      header: t('rsp:pages.organizations.list.settings'),
      cell: ({ row }) => renderSettingsType(row.original.usesDefaultSettings),
    },
    {
      accessorKey: 'status',
      meta: { ...defaultMeta },
      enableSorting: true,
      header: t('rsp:pages.organizations.list.status'),
      cell: ({ row }) => renderStatusBadge(row.original.isSuspended),
    },
    {
      accessorKey: 'actions',
      meta: { ...defaultMeta },
      header: String(),
      enableSorting: false,
      cell: ({ row }) => renderActionsDropdown(row.original),
      minSize: 20,
    },
  ]

  return (
    <Card padded>
      {isRequestLoading(organizationsQuery) ? (
        <SkeletonTable />
      ) : (
        <DataTableServerPagination
          columns={columns}
          data={organizationsQuery?.data}
          dataTestId="tableOrganizations"
          isFetching={organizationsQuery.isFetching}
          emptyMessage={t('rsp:pages.organizations.noOrganization')}
          filterPlaceholder={t('rsp:pages.organizations.list.filterPlaceholder')}
          fieldDescription={t('rsp:pages.organizations.list.searchFieldDescription')}
          onSortBy={onSortBy}
          setPageParams={setOptions}
        />
      )}
    </Card>
  )
}

export default OrganizationsTable
