import {
  Currency as ApiCurrency,
  CommitmentTermType,
  Offer,
  Product,
} from '@sherweb/core/openapi-generated/index.defs'

import { Currency, MoneyAmount } from '@sherweb/core/common/money'

import { IShopProduct, ShopOffer } from './shop.model'

export const mapCurrency = (currency: ApiCurrency): Currency => {
  switch (currency) {
    case ApiCurrency.Cad:
      return Currency.CAD
    case ApiCurrency.Usd:
    default:
      return Currency.USD
  }
}

export const buildProduct = (response?: Product): IShopProduct | null => {
  if (!response) {
    return null
  }

  const offersByCommitmentDict: Record<CommitmentTermType, ShopOffer[]> = {
    [CommitmentTermType.Unknown]: [],
    [CommitmentTermType.Monthly]: [],
    [CommitmentTermType.Annual]: [],
    [CommitmentTermType.Biennial]: [],
    [CommitmentTermType.Triennial]: [],
  }

  response?.offers?.forEach(offer => {
    const { commitmentTermType } = offer

    if (commitmentTermType) {
      offersByCommitmentDict[commitmentTermType].push(buildOffer(offer, response.currency))
    }
  })

  return {
    ...response,
    offersByCommitmentTerm: offersByCommitmentDict,
  }
}

export const buildOffer = (offerResponse: Offer, currency: ApiCurrency): ShopOffer => {
  const price: MoneyAmount = { currency: mapCurrency(currency), amount: offerResponse.price }

  return {
    ...offerResponse,
    price,
  }
}
