import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom'

import { CoreRoutes } from '@sherweb/core/common/coreRoutes'
import { withLazyLoad } from '@sherweb/core/hoc'

import RspGate from '@rsp/app/AppGate'
import RspRoutes from '@rsp/app/Routes'
import RspError401 from '@rsp/pages/errors/Error401'
import RspError403 from '@rsp/pages/errors/Error403'
import RspError404 from '@rsp/pages/errors/Error404'
import RspError500 from '@rsp/pages/errors/Error500'
import IntegrationsPage from '@rsp/pages/integrations'
import ConnectWisePage from '@rsp/pages/integrations/helpdesk/connectwise'
import ApiInformation from '@rsp/pages/integrations/helpdesk/connectwise/ApiInformation'
import BoardConfiguration from '@rsp/pages/integrations/helpdesk/connectwise/BoardConfiguration'
import OrganizationMapping from '@rsp/pages/integrations/helpdesk/connectwise/OrganizationMapping'
import SherwebServiceProviderPage from '@rsp/pages/integrations/serviceProvider/sherweb'
import RspLoginPage from '@rsp/pages/login'
import OnboardingPage from '@rsp/pages/onboarding/OnboardingPage'
import { OrderApprovalDecision } from '@rsp/pages/orders/components/OrderApprovalDecision'
import OrderDetailPage from '@rsp/pages/orders/detail/OrderDetailPage'
import OrdersPage from '@rsp/pages/orders/OrdersPage'
import OrganizationDetailPage from '@rsp/pages/organizations/detail/OrganizationDetailPage'
import OrganizationsPage from '@rsp/pages/organizations/OrganizationsPage'
import SettingsPage from '@rsp/pages/settings'

import SspGate from '@ssp/app/AppGate'
import SspRoutes from '@ssp/app/Routes'
import DashboardPage from '@ssp/pages/dashboard'
import Error401 from '@ssp/pages/errors/Error401'
import Error403 from '@ssp/pages/errors/Error403'
import Error404 from '@ssp/pages/errors/Error404'
import Error500 from '@ssp/pages/errors/Error500'
import HelpDeskTicketCreatePage from '@ssp/pages/helpdesk/create'
import HelpDeskTicketDetailPage from '@ssp/pages/helpdesk/detail'
import HelpDeskPage from '@ssp/pages/helpdesk/ticket'
import LicensesPage from '@ssp/pages/licenses'
import SspLoginPage from '@ssp/pages/login'
import SspOrdersPage from '@ssp/pages/orders/OrdersPage'
import OrganizationDispatcherPage from '@ssp/pages/organization/OrganizationDispatcher'
import OrganizationsNotActivated from '@ssp/pages/organization/OrganizationsNotActivated'
import OrganizationSuspended from '@ssp/pages/organization/OrganizationSuspended'
import ShopPage from '@ssp/pages/shop'
import ShopProductDetailPage from '@ssp/pages/shop/detail'
import SubscriptionPage from '@ssp/pages/subscription'
import SubscriptionsPage from '@ssp/pages/subscriptions'
import UserEditPage from '@ssp/pages/user'
import UserInvitePage from '@ssp/pages/userInvite'
import UsersPage from '@ssp/pages/users'

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/admin" element={withLazyLoad(<RspGate />)}>
        <Route index element={<Navigate replace to={RspRoutes.Orders} />} />
        <Route path={RspRoutes.Orders} element={<OrdersPage />}>
          <Route path={RspRoutes.Order} element={<OrderDetailPage />} />
          <Route path={RspRoutes.OrderDecision} element={<OrderApprovalDecision />} />
        </Route>
        <Route path={RspRoutes.Settings} element={<SettingsPage />} />
        <Route path={RspRoutes.Onboarding} element={<OnboardingPage />} />
        <Route path={RspRoutes.Integrations} element={<IntegrationsPage />} />
        <Route path={RspRoutes.Organizations} element={<OrganizationsPage />}>
          <Route path={RspRoutes.Organization} element={<OrganizationDetailPage />} />
        </Route>
        <Route
          path={RspRoutes.SherwebIntegrationsServiceProvider}
          element={<SherwebServiceProviderPage />}
        />
        <Route path={RspRoutes.IntegrationsConnectWise} element={<ConnectWisePage />}>
          <Route
            index
            element={<Navigate replace to={RspRoutes.IntegrationsConnectWiseApiInformation} />}
          />
          <Route
            path={RspRoutes.IntegrationsConnectWiseApiInformation}
            element={<ApiInformation />}
          />
          <Route
            path={RspRoutes.IntegrationsConnectWiseBoardConfiguration}
            element={<BoardConfiguration />}
          />
          <Route
            path={RspRoutes.IntegrationsConnectWiseOrganizationMapping}
            element={<OrganizationMapping />}
          />
        </Route>
        <Route path={RspRoutes.Login} element={<RspLoginPage />} />
        <Route path={RspRoutes.InternalError} element={<RspError500 className="h-screen" />} />
        <Route path={RspRoutes.UnauthorizedAccess} element={<RspError401 className="h-screen" />} />
        <Route path={RspRoutes.ForbiddenAccess} element={<RspError403 className="h-screen" />} />
        <Route path={RspRoutes.NotFound} element={<RspError404 className="h-screen" />} />
        <Route path="*" element={<RspError404 />} />
      </Route>
      <Route path="/" element={withLazyLoad(<SspGate />)}>
        <Route path={SspRoutes.Login} element={<SspLoginPage />} />
        <Route path={SspRoutes.Root} element={<OrganizationDispatcherPage />} />
        <Route path={CoreRoutes.InternalError} element={<Error500 className="h-screen" />} />
        <Route path={CoreRoutes.UnauthorizedAccess} element={<Error401 className="h-screen" />} />
        <Route path={CoreRoutes.ForbiddenAccess} element={<Error403 className="h-screen" />} />
        <Route path={CoreRoutes.NotFound} element={<Error404 className="h-screen" />} />
        <Route path={SspRoutes.OrganizationsNotActivated} element={<OrganizationsNotActivated />} />
        <Route path={SspRoutes.OrganizationDispatcher} element={<OrganizationDispatcherPage />}>
          <Route path={SspRoutes.OrganizationSuspended} element={<OrganizationSuspended />} />
          <Route path={SspRoutes.Dashboard} element={<DashboardPage />} />
          <Route path={SspRoutes.Subscriptions} element={<SubscriptionsPage />} />
          <Route path={SspRoutes.Subscription} element={<SubscriptionPage />} />
          <Route path={SspRoutes.Licenses} element={<LicensesPage />} />
          <Route path={SspRoutes.Users} element={<UsersPage />} />
          <Route path={SspRoutes.Shop} element={<ShopPage />}>
            <Route path={SspRoutes.ShopProductDetail} element={<ShopProductDetailPage />} />
          </Route>
          <Route path={SspRoutes.Orders} element={<SspOrdersPage />} />
          <Route path={SspRoutes.UsersInvite} element={<UserInvitePage />} />
          <Route path={SspRoutes.UserEdit} element={<UserEditPage />} />
          <Route path={SspRoutes.HelpDesk} element={<HelpDeskPage />}>
            <Route path={SspRoutes.HelpDeskCreate} element={<HelpDeskTicketCreatePage />} />
            <Route path={SspRoutes.HelpDeskTicketDetail} element={<HelpDeskTicketDetailPage />} />
          </Route>
          <Route path="*" element={<Error404 />} />
        </Route>
      </Route>
      <Route path="*" element={<Error404 />} />
    </>
  ),
  { basename: process.env.PUBLIC_URL }
)
