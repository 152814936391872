import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'

import Button, { Variant } from '@sherweb/core/components/Button'
import { Typography } from '@sherweb/core/components/Typography'

import Routes from '@ssp/app/Routes'
import { useNavigate, usePathGenerator } from '@ssp/modules/navigation'
import { useSelectedOrganization } from '@ssp/modules/organization'

type ShopDetailOrderCreatedProps = {
  isSuccess?: boolean
  onContinueShopping: () => void
}

export const ShopDetailOrderCreated = ({
  isSuccess,
  onContinueShopping,
}: ShopDetailOrderCreatedProps) => {
  const organization = useSelectedOrganization()

  const generatePath = usePathGenerator()

  const { t } = useTranslation()

  const { navigate } = useNavigate()

  const handleGoToOrders = () => {
    navigate(
      generatePath(Routes.Orders, {
        organizationUniqueName: organization?.uniqueName ?? '',
      })
    )
  }

  const handleGoToShopMore = () => {
    onContinueShopping()
  }

  return (
    <>
      <div className="mt-2 flex flex-col items-center justify-center gap-2">
        {isSuccess ? (
          <>
            <CheckCircleIcon className="h-[140px] w-[140px] text-green-500" />
            <Typography variant="heading6">{t('ssp:pages.shop.detail.order.success')}</Typography>
            <Typography variant="body2">
              {t('ssp:pages.shop.detail.order.successContent')}
            </Typography>
          </>
        ) : (
          <>
            <XCircleIcon className="h-[140px] w-[140px] text-red-500" />
            <Typography variant="heading6">{t('ssp:pages.shop.detail.order.failed')}</Typography>
            <Typography variant="body2">
              {t('ssp:pages.shop.detail.order.failedContent')}
            </Typography>
          </>
        )}
      </div>
      <div className="mb-4 flex w-full flex-col items-center justify-between gap-2 sm:flex-row sm:items-start">
        <Button size="sm" variant={Variant.Outline} onClick={handleGoToOrders}>
          {t('ssp:pages.shop.detail.order.status')}
        </Button>
        <Button
          size="sm"
          variant="None"
          className="bg-indigo-600 hover:bg-indigo-700"
          onClick={handleGoToShopMore}
        >
          {t('ssp:pages.shop.detail.order.continueShopping')}
        </Button>
      </div>
    </>
  )
}
