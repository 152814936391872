import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import Card from '@sherweb/core/components/Card/Card'
import PageTitle, { PageTitleWrapper } from '@sherweb/core/components/PageTitle'
import Spinner from '@sherweb/core/components/Spinner/Spinner'
import { isRequestHavingNoResults, isRequestLoading } from '@sherweb/core/modules/reactQuery'

import { protectPage } from '@rsp/app/ProtectedPage'
import ContainedPage from '@rsp/components/layout/ContainedPage'
import { useGetOrdersQuery } from '@rsp/modules/orders'

import OrdersTable from './OrdersTable'
import OrdersTableMobile from './OrdersTableMobile'

const OrdersPage = () => {
  const { t } = useTranslation()

  const ordersQuery = useGetOrdersQuery()

  const isOrdersLoading = isRequestLoading(ordersQuery)

  const noOrders = isRequestHavingNoResults(ordersQuery)

  return (
    <>
      <ContainedPage>
        {isOrdersLoading ? (
          <Spinner floating />
        ) : (
          <div className="flex flex-col gap-12 md:gap-16">
            <PageTitleWrapper
              title={
                <PageTitle data-testid="pageOrdersTitle">{t('rsp:pages.orders.title')}</PageTitle>
              }
            />
            {noOrders ? (
              <div
                className="flex w-full flex-col items-center justify-center gap-4 rounded-lg border-2 border-dashed border-gray-300 p-8 font-normal text-gray-400 md:h-60"
                data-testid="containerNoOrders"
              >
                <ArchiveBoxXMarkIcon className="h-16 w-16 stroke-1 text-gray-400" />
                <p>{t('rsp:pages.orders.noOrders')}</p>
              </div>
            ) : (
              <>
                <div className="hidden lg:block">
                  <Card padded>
                    <OrdersTable />
                  </Card>
                </div>
                <div className="mt-4 lg:hidden">
                  <Card padded>
                    <OrdersTableMobile />
                  </Card>
                </div>
              </>
            )}
          </div>
        )}
      </ContainedPage>
      <Outlet />
    </>
  )
}

export default protectPage(OrdersPage)
