import { useTranslation } from 'react-i18next'

import { OrganizationOrderQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import {
  Accordion,
  AccordionContent,
  AccordionContentList,
  AccordionContentListItem,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerSubContent,
} from '@sherweb/core/components/Accordion'
import { DataTableMobileHeader } from '@sherweb/core/components/DataTable/DataTableInputMobile'
import { useSetTableSearchParams } from '@sherweb/core/components/DataTable/hooks/useSetTableSearchParam'
import { dateFormatTypes, useDateFormatter } from '@sherweb/core/utils/date'

import { useOrders } from '@ssp/modules/orders'

import { useRenderOrderType, useRenderStatusBadge } from '../orders.helper'

export const OrdersTableMobile = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const orders = useOrders()

  const renderStatusBadge = useRenderStatusBadge()

  const renderOrderType = useRenderOrderType()

  const { search: searchText, onSetSearchParams } = useSetTableSearchParams()

  const formatDate = useDateFormatter(language, dateFormatTypes.datetime)

  const filteredData = orders?.data?.filter((order: OrganizationOrderQueryResult) => {
    return (
      (order.humanReadableId ?? '').toLowerCase().includes(searchText.toLowerCase()) ||
      order.productName?.toLowerCase().includes(searchText.toLowerCase())
    )
  })

  return (
    <div className="flex flex-col gap-4">
      <DataTableMobileHeader>
        <DataTableMobileHeader.Input
          placeholder={t('ssp:pages.orders.filterPlaceholder')}
          filteredText={searchText}
          setFilteredText={onSetSearchParams}
        />
      </DataTableMobileHeader>
      {filteredData?.map((order: OrganizationOrderQueryResult) => {
        return (
          <Accordion key={order.orderId} type="single" collapsible>
            <AccordionItem value="item-1">
              <AccordionTrigger className="relative justify-between rounded-xl bg-white p-4 [&[data-state=open]>div>div]:hidden [&[data-state=open]]:rounded-b-none">
                {order.humanReadableId} - {order.productName}
                <AccordionTriggerSubContent>
                  {renderStatusBadge(order.orderStatus)}
                </AccordionTriggerSubContent>
              </AccordionTrigger>
              <AccordionContent>
                <AccordionContentList>
                  <AccordionContentListItem
                    label={t('ssp:pages.orders.list.id')}
                    value={order.humanReadableId}
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.orders.list.productName')}
                    value={order.productName}
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.orders.list.createdOn')}
                    value={formatDate(order.createdOn)}
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.orders.list.type')}
                    value={renderOrderType(order.type)}
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.orders.list.quantity')}
                    value={order.requestedQuantity}
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.orders.list.status')}
                    value={renderStatusBadge(order.orderStatus)}
                  />
                </AccordionContentList>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )
      })}
    </div>
  )
}

export default OrdersTableMobile
