import { DEFAULT_TABLE_ROWS_PER_PAGE } from '@sherweb/core/utils/const'

import { Pagination } from './types'

export const getDefaultParameters = <T>(options?: Partial<Pagination<T>>): Pagination<T> => {
  return {
    page: 1,
    pageSize: DEFAULT_TABLE_ROWS_PER_PAGE,
    searchTerm: '',
    ...options,
  }
}
