import { Loader2 } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import Alert, { Variant } from '@sherweb/core/components/Alert'
import Button from '@sherweb/core/components/Button'
import { useQueryParams } from '@sherweb/core/hooks'

import { HasAccess } from '@ssp/modules/authorization'

type ShopDetailCreateSubscriptionProps = {
  isLoading?: boolean
  disabled: boolean
  setConfirmationDialogOpened: (open: boolean) => void
}
export const ShopDetailCreateSubscription = ({
  isLoading,
  disabled,
  setConfirmationDialogOpened,
}: ShopDetailCreateSubscriptionProps) => {
  const { t } = useTranslation()

  const { getParams } = useQueryParams<'productQuantity'>()

  const quantity = Number(getParams('productQuantity'))

  return (
    <HasAccess permission={Permission.MarketplaceProductEdit}>
      <div className="mt-6 flex w-full flex-col gap-y-2">
        <Button
          className="w-full"
          variant="primary"
          data-testid="btnCreateNewSubscription"
          onClick={() => setConfirmationDialogOpened(true)}
          disabled={disabled || quantity === 0 || isLoading}
        >
          {isLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
          {t('ssp:pages.shop.detail.create')}
        </Button>
        {disabled ? (
          <Alert variant={Variant.Warning} data-testid="alertAlreadyActiveSubscription">
            {t('ssp:pages.shop.detail.alreadyActiveSubscription')}
          </Alert>
        ) : null}
      </div>
    </HasAccess>
  )
}
