import { ArrowsUpDownIcon, CheckIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ProductSort } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@sherweb/core/components/DropdownMenu'
import { Skeleton } from '@sherweb/core/components/Skeleton'
import { Typography } from '@sherweb/core/components/Typography'
import { useQueryParams } from '@sherweb/core/hooks'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import { DEFAULT_FILTER } from '../shop.const'

type ShopSortProps = {
  isLoading?: boolean
}

export const ShopSort = ({ isLoading }: ShopSortProps) => {
  const { t } = useTranslation()

  const options = [
    {
      label: t('ssp:pages.shop.sort.productNameAscending'),
      value: ProductSort.ProductNameAscending,
    },
    {
      label: t('ssp:pages.shop.sort.productNameDescending'),
      value: ProductSort.ProductNameDescending,
    },
    {
      label: t('ssp:pages.shop.sort.priceAscending'),
      value: ProductSort.PriceAscending,
    },
    {
      label: t('ssp:pages.shop.sort.priceDescending'),
      value: ProductSort.PriceDescending,
    },
  ]

  const { getParams, setSearchParams } = useQueryParams<'productsSortBy'>()

  const querySortBySelectedValue = getParams<ProductSort>('productsSortBy')

  const [dropdownOpen, setDropdownOpen] = useState(false)

  useEffect(() => {
    if (!querySortBySelectedValue && querySortBySelectedValue !== DEFAULT_FILTER.sortBy) {
      setSearchParams('productsSortBy', DEFAULT_FILTER.sortBy)
    }
  }, [querySortBySelectedValue, setSearchParams])

  if (isLoading) {
    return <Skeleton className="h-[36px] w-[112px]" />
  }

  return (
    <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
      <DropdownMenuTrigger asChild data-testid="dropdownSort">
        <Button
          size="small"
          variant="None"
          className={mergeClassName(
            'flex items-center px-4 py-2 text-slate-900 hover:bg-white/50 dark:text-slate-100',
            {
              'bg-white/50': querySortBySelectedValue,
            }
          )}
        >
          <Typography
            as="div"
            variant="body2"
            className={mergeClassName('flex items-center', {
              'text-indigo-600': querySortBySelectedValue,
            })}
          >
            <ArrowsUpDownIcon className="mr-1 h-4 w-4" />
            {options.find(option => option.value === getParams('productsSortBy'))?.label}
          </Typography>
          <div
            className={mergeClassName(
              'ml-2 mt-1 border-4 border-solid border-transparent border-t-black',
              {
                'border-t-indigo-600': querySortBySelectedValue,
              }
            )}
          />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="min-w-[225px]"
        onFocusOutside={() => {
          setDropdownOpen(false)
        }}
      >
        {options?.map(option => (
          <DropdownMenuItem
            key={option.value}
            data-testid="dropdownItemSort"
            onSelect={() => {
              setSearchParams('productsSortBy', option.value)
            }}
          >
            {getParams('productsSortBy') === option.value ? (
              <CheckIcon className="h-4 w-4 text-indigo-600" />
            ) : null}
            <Typography
              as="div"
              variant="body2"
              className={mergeClassName('ml-2', {
                'text-indigo-600': getParams('productsSortBy') === option.value,
              })}
            >
              {option.label}
            </Typography>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
