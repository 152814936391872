import { useTranslation } from 'react-i18next'

import { Separator } from '@sherweb/core/components/Separator'
import { SheetPage } from '@sherweb/core/components/Sheet'
import { Typography } from '@sherweb/core/components/Typography'
import { useDateFormatter } from '@sherweb/core/utils/date'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import { Order } from '@rsp/modules/orders'
import {
  getServerError,
  useOrderStatusBadge,
  useRenderOrderType,
} from '@rsp/modules/orders/core/orders.helpers'

import { OrderCreator } from '../components/OrderCreator'

type OrderDetailContentProps = {
  order?: Order
}

export const OrderDetailContent = ({ order }: OrderDetailContentProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const formatDate = useDateFormatter(language)

  const formatMoneyAmount = useMoneyFormatter(language)

  const getOrderStatusBadge = useOrderStatusBadge()

  const renderOrderType = useRenderOrderType()

  const hasComment = Boolean(order?.error?.message ?? order?.decisionNote)

  const hasServiceProviderError = Boolean(order?.error?.title)

  if (!order) {
    return null
  }

  return (
    <>
      <Separator className="bg-slate-300 dark:bg-slate-700" />
      <SheetPage.Layout>
        <SheetPage.Row>
          <SheetPage.Column>
            <SheetPage.Content>
              <SheetPage.ContentLabel>
                {t('rsp:pages.orders.list.organizationName')}
              </SheetPage.ContentLabel>
              <SheetPage.ContentValue>{order?.organizationUniqueName}</SheetPage.ContentValue>
            </SheetPage.Content>
            <SheetPage.Content className="mt-2">
              <SheetPage.ContentLabel>
                {t('rsp:pages.orders.list.createdOn')}
              </SheetPage.ContentLabel>
              <SheetPage.ContentValue>{formatDate(order?.createdOn)}</SheetPage.ContentValue>
            </SheetPage.Content>
            <SheetPage.Content>
              <SheetPage.ContentLabel>
                {t('rsp:pages.orders.list.orderValue')}
              </SheetPage.ContentLabel>
              <SheetPage.ContentValue>
                {order.orderValue ? formatMoneyAmount(order.orderValue) : null}
              </SheetPage.ContentValue>
            </SheetPage.Content>
            <SheetPage.Content>
              <SheetPage.ContentLabel>{t('rsp:pages.order.productName')}</SheetPage.ContentLabel>
              <SheetPage.ContentValue>{order?.firstProductName}</SheetPage.ContentValue>
            </SheetPage.Content>
          </SheetPage.Column>
          <SheetPage.Column>
            <SheetPage.Content>
              <SheetPage.ContentLabel>
                {t('ssp:pages.helpdesk.detail.createdBy')}
              </SheetPage.ContentLabel>
              <SheetPage.ContentValue>
                <OrderCreator
                  firstName={order?.userFirstName}
                  lastName={order?.userLastName}
                  email={order?.userEmail}
                />
              </SheetPage.ContentValue>
            </SheetPage.Content>
            <SheetPage.Content>
              <SheetPage.ContentLabel>{t('rsp:pages.orders.list.status')}</SheetPage.ContentLabel>
              <SheetPage.ContentValue>
                {getOrderStatusBadge(order?.orderStatus)}
              </SheetPage.ContentValue>
            </SheetPage.Content>
            <SheetPage.Content>
              <SheetPage.ContentLabel>{t('rsp:pages.orders.list.type')}</SheetPage.ContentLabel>
              <SheetPage.ContentValue>{renderOrderType(order?.orderType)}</SheetPage.ContentValue>
            </SheetPage.Content>
            <SheetPage.Content>
              <SheetPage.ContentLabel>{t('rsp:pages.order.quantity')}</SheetPage.ContentLabel>
              <SheetPage.ContentValue>{order?.firstQuantity}</SheetPage.ContentValue>
            </SheetPage.Content>
          </SheetPage.Column>
        </SheetPage.Row>
      </SheetPage.Layout>
      <Separator className="bg-slate-300 dark:bg-slate-700" />
      <SheetPage.Layout className="flex flex-col gap-y-2">
        <div className="flex flex-col gap-y-6">
          {hasComment ? (
            <div className="flex flex-col gap-y-1">
              <Typography variant="body2" weight="medium">
                {t('rsp:pages.orders.list.comment')}
              </Typography>
              <Typography variant="body2">
                {order?.error?.message ?? order?.decisionNote}
              </Typography>
            </div>
          ) : null}
          {hasServiceProviderError ? (
            <div className="flex flex-col gap-y-1">
              <Typography variant="body2" weight="medium" as="div">
                {t('rsp:pages.order.serverError')}
              </Typography>
              <Typography variant="body2" as="div">
                {getServerError(order?.error)}
              </Typography>
            </div>
          ) : null}
        </div>
      </SheetPage.Layout>
    </>
  )
}
